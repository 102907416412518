// Fonts
// @font-face {
// 	font-family: "La Nord V2.1 Semi";
// 	src: url("../fonts/LaNordV21-SemiLight.woff2") format("woff2"),
// 		url("../fonts/LaNordV21-SemiLight.woff") format("woff");
// 	font-weight: 200;
// 	font-style: normal;
// 	font-display: swap;
// }

// @font-face {
// 	font-family: "La Nord V2.1 Semi Italic";
// 	src: url("../fonts/LaNordV2.1-SemiLightItalic.woff") format("woff");
// 	font-weight: 200;
// 	font-style: italic;
// 	font-display: swap;
// }

// @font-face {
// 	font-family: "La Nord V2.1";
// 	src: url("../fonts/LaNordV21-Light.woff2") format("woff2"),
// 		url("../fonts/LaNordV21-Light.woff") format("woff");
// 	font-weight: 300;
// 	font-style: normal;
// 	font-display: swap;
// }

// @font-face {
// 	font-family: "La Nord V2.1";
// 	src: url("../fonts/LaNordV21-Regular.woff2") format("woff2"),
// 		url("../fonts/LaNordV21-Regular.woff") format("woff");
// 	font-weight: 400;
// 	font-style: normal;
// 	font-display: swap;
// }

// @font-face {
// 	font-family: "La Nord V2.1";
// 	src: url("../fonts/LaNordV21-Medium.woff2") format("woff2"),
// 		url("../fonts/LaNordV21-Medium.woff") format("woff");
// 	font-weight: 500;
// 	font-style: normal;
// 	font-display: swap;
// }

// @font-face {
// 	font-family: "La Nord V2.1";
// 	src: url("../fonts/LaNordV21-Bold.woff2") format("woff2"),
// 		url("../fonts/LaNordV21-Bold.woff") format("woff");
// 	font-weight: 700;
// 	font-style: normal;
// 	font-display: swap;
// }


@font-face {
	font-family: "ABCMonumentGrotesk";
	src: url("../fonts/ABCMonumentGrotesk-Regular.woff2") format("woff2"),
		url("../fonts/ABCMonumentGrotesk-RegularItalic.woff") format("woff");
	font-weight: 400;
	font-style: normal;
	font-display: swap;
}
@font-face {
	font-family: "ABCMonumentGroteskMono_medium";
	src: url("../fonts/ABCMonumentGroteskMono-Medium.woff2") format("woff2"),
		url("../fonts/ABCMonumentGroteskMono-Medium.woff") format("woff");
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}
@font-face {
	font-family: "ABCMonumentGrotesk_mono";
	src: url("../fonts/MonumentGrotesk-Semi-Mono.woff2") format("woff2"),
		url("../fonts/MonumentGrotesk-Semi-Mono.woff") format("woff");
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}
@font-face {
	font-family: "ABCMonumentGrotesk_bold";
	src: url("../fonts/ABCMonumentGrotesk-Bold.woff2") format("woff2"),
		url("../fonts/ABCMonumentGrotesk-Bold.woff") format("woff");
	font-weight: 700;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "Pangea";
	src:url('../fonts/Pangea-Regular.eot'); 
	src:url('../fonts/Pangea-Regular.ttf') format('truetype'),url('../fonts/Pangea-Regular.woff') format('woff'),  url('../fonts/Pangea-Regular.svg') format('svg');
	//src: url("../fonts/Pangea-Regular.woff") format("woff");
	src: url("../fonts/Pangea-Regular.woff2") format("woff2");
    font-style: normal;
	font-display: swap;
}
// @font-face {
//     font-family: "Pangea_bold";
// 	src: url('../fonts/Pangea-Bold.eot');
// 	src: local('☺'), url('../fonts/Pangea-Bold.ttf') format('truetype'),url('../fonts/Pangea-Bold.woff') format('woff'),  url('../fonts/Pangea-Bold.svg') format('svg');
// 	font-weight: normal;
// 	font-style: normal;
// }
// @font-face {
// 	font-family: "Pangea_medium";
// 	src: url('../fonts/Pangea-Medium.eot');
// 	src: local('☺'),  url('../fonts/Pangea-Medium.ttf') format('truetype'),url('../fonts/Pangea-Medium.woff') format('woff'), url('../fonts/Pangea-Medium.svg') format('svg');
// 	// src: url("../fonts/Pangea-Medium.woff2") format("woff2");
// 	font-weight: normal;
// 	// src: url("../fonts/Pangea-Medium.woff") format("woff");
// 	// src: url("../fonts/Pangea-Medium.woff2") format("woff2");
// 	font-display: swap;
// }
// @font-face {
// 	font-family: "Pangea_light";
// 	src: url('../fonts/Pangea-Light.eot');
// 	src: local('☺'), url('../fonts/Pangea-Light.ttf') format('truetype'),url('../fonts/Pangea-Light.woff') format('woff'),  url('../fonts/Pangea-Light.svg') format('svg');
// 	font-weight: normal;
// 	// src: url("../fonts/Pangea-Light.woff2") format("woff2");
// 	font-display: swap;
// }

h1 {
	@include defaultFontStyle(400, 80, 75, $black);
	letter-spacing: -1.92px;
	@include mediaQuery($large) {
		@include mediaFontStyle(40, 60);
	}
	@include mediaQuery($mobile) {
		@include mediaFontStyle(30, 4s0);
	}
}
h2 {
	@include defaultFontStyle(400, 40, 40, $black);
	letter-spacing: -0.96px;
	@include mediaQuery($large) {
		@include mediaFontStyle(30, 40);
	}
	@include mediaQuery($mobile) {
		@include mediaFontStyle(20, 30);
	}
}
h3 {
	@include defaultFontStyle(400, 22, 27, $black);
	letter-spacing: 0px;
}
p {
	@include defaultFontStyle(400, 16, 23, $black);
	letter-spacing: -0.38px;
	@include mediaQuery($smallMobile) {
		@include mediaFontStyle(14, 20);
	}
}
