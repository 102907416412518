.my_screening{
    .sd_header_section{
        height: auto;
        // padding-bottom: 182px;
        // background: url('../../images/header_bg.png') no-repeat;        
        background-size: 100% 100%; 
        @include mediaQuery($ipad){      
            background-size: cover;   
            // padding-bottom: 120px;
        }
    } 
}
.sd_main_screening_title
{
    background: url('../../images/privet_screening_bg.png') no-repeat;
    background-size: cover;
    background-position: right;
    .sd_inner_container{
        max-width: 1520px;
        width: 100%;
        margin: 0 auto;
        padding: 0 20px;
        @media (min-width: 1600px) and (max-width: 1680px){
            max-width: 100%;
            padding: 0 20px 0 100px;  
        }
        @include mediaQuery(480px){
            padding: 0 15px;
        }
    }
    .sd_screening_title{
        padding: 74px 0;
        width: 100%;
        @include mediaQuery(600px){
            padding: 50px 0;
        }
        h1{
            @include defaultFontStyleScreening($font_family_bold,80, 69, $white, 0.2px);  
            font-weight: 700;
            text-transform: capitalize;  
            @include mediaQuery(1180px){
                @include mediaFontStyle(70, 80);  
                text-align: center
            }
            @include mediaQuery(991px){
                @include mediaFontStyle(50, 55);
            }
            @include mediaQuery(600px){
                @include mediaFontStyle(40, 50); 
            }
            @include mediaQuery(480px){
                @include mediaFontStyle(60,58);
                text-align: start;
            }
            // @include mediaQuery(360px){
            //     @include mediaFontStyle(24, 30);
            // }
        }
        p{
            @include defaultFontStyle(400, 22, 32, $black);  
            max-width: 675px;
            padding-top: 20px; 
            @include mediaQuery($ipad){
                @include defaultFontStyle(400, 18, 30, $black);     
    
            }
        }
    }
}
.sd_my_screening{
    padding: 65px 0 46px;
    @include mediaQuery(767px){
        padding: 40px 0 10px;
    }
    .sd_inner_container{
        max-width: 100%;
        padding: 0;
    }
    .sd_screening_blog{
        padding-bottom: 60px;
        &:last-child{
            padding-bottom: 0;
        }
        @include mediaQuery(480px){
            padding-bottom: 20px;
            padding: 0 15px 20px;
        }
        .sd_filmcard_title{
            position: relative;
            padding: 0 20px;
            max-width: 1520px;
            width: 100%;
            margin: 0 auto;
    
            @media (min-width: 1600px) and (max-width: 1680px){
                max-width: 100%;
                padding: 0 0 0 100px;
            }
            @include mediaQuery(480px){
                &{
                    padding: 0;
                }
            }
            @include mediaQuery(320px){
                &{
                    &::after{
                        display: none;
                    }
                }
            }
            img,h3{
                z-index: 99999;
                background: #fff;
                padding-right: 20px;
                max-width: 200px;
            }
            img{
                @include mediaQuery(768px){
                    max-width: 180px;
                } 
                @include mediaQuery(320px){
                    padding: 0;
                    margin: 0 auto;
                } 
            }
            h3{
                max-width: unset;
                width: auto;
                display: unset;
                @include mediaQuery(380px){
                    text-align: center;
                    padding: 0;
                    font-size: 30px;
                } 
            }
            &::after{
                content: '';
                position: absolute;
                right: 0;
                top: 50%;
                background: #000;
                overflow: hidden;
                z-index: -1;
                border-top: 2px solid #000;
                width: calc(100% - 100px);
            }
        }
        .sd_filmcard{
            padding: 35px 10px 0;
            display: flex;
            flex-wrap: wrap;
            max-width: 1520px;
            width: 100%;
            margin: 0 auto;

            @media (min-width: 1600px) and (max-width: 1680px){
                max-width: 100%;
                padding: 50px 90px 0;
            }
            @include mediaQuery(1600px){
                padding: 50px 8px 0;
            }
            @include mediaQuery(600px){
                padding: 50px 20px 0;
            }
            @include mediaQuery(480px){
                padding: 50px 0 0;
            }
        }
    }
}