// Home


// Video
.vid_wrapper{
    position: relative;
    padding-bottom: 56.25%; /* 16:9 */
    padding-top: 25px;
    height: 0;
    iframe{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}
.player_wrap{
    overflow: hidden;
}
// .sd_video_screen_section{
//     padding: 46px 0 82px; 
// }


// Warning
.sd_films_content_warning{
    text-align: center;
    // padding-top: 15px;
    padding: 58px 0 20px;
    &:empty{
        display: none;
    }
    @include mediaQuery(767px){
        padding: 20px 15px;
    }
    p{
        @include defaultFontStyleScreening($font_family_medium,18, 24, $black, -0.432px); 
        font-weight: 400;
        span{
            font-weight: 700;
        }
        // @include mediaQuery(480px){
        //     @include mediaFontStyle(16, 24); 
        // }
    }
}

// Info blocks
.sd_home_info_section{
    border-top: 2px solid $black;
    background: $pink;
    .sd_inner_container{
        @include mediaQuery($large) {
            padding: 0;
        }

    }
    .sd_home_info_sec{
        @include flex;
        @include mediaQuery($large) {
            @include flex_dir_column;
        }
    }
    .sd_home_info_block{
        border-right: 2px solid $black;
        max-width: 33.33%;  
        padding: 60px 60px 35px 40px;
        &:last-child{
            border-right: 0;
            @include mediaQuery($large) {
                border-bottom: 0;
            }
        }
        @include mediaQuery($desktop) {
            padding: 40px 40px 30px 20px;
        }
        @include mediaQuery($large) {
            max-width: 100%;  
            border-right: 0;
            border-bottom: 2px solid $black;
        }

        h2{
            margin-bottom: 10px;
            @include mediaQuery($extraLarge) {
                @include mediaFontStyle(36, 40);
            }
            @include mediaQuery($ipad) {
                @include mediaFontStyle(30, 38);
            }
            @include mediaQuery($mobile) {
                @include mediaFontStyle(24, 34);
            }
        }
        p{
            @include defaultFontStyle(400, 18, 24, $black);
            padding-bottom: 50px;
            @include mediaQuery($desktop) {
                padding-bottom: 20px; 
            }
        }
        a{
            @include flex_align_center;
            margin-top: 40px;
            @include defaultFontStyle(500, 22, 32, $black, -0.53px);
            @include mediaQuery($large) {
                margin-top: 20px;
            }
            @include mediaQuery($mobile) {
                @include mediaFontStyle(18, 28);
            }
            img{
                width: 18px;
                margin-left: 14px;
                @include mediaQuery($mobile) {
                    width: 14px;
                }
            }
        }
    }
}


// Brought by

.sd_outer_brought{
    @include flex_align_center;
    @include justify_center;
    overflow: hidden;
    margin-bottom: 50px;
    .sd_brought_by{
        padding: 0 33px;
        max-width: 300px;
        overflow: hidden;
        background: $white;
        position: relative;
        z-index: 2;
        text-align: center;
        p{
            @include mediaFontStyle(10,20);
            margin-bottom: 10px;
            text-transform: uppercase;
        }
        @include mediaQuery(600px){
            padding: 0 20px;
            max-width: 260px;
        }
    }
    &:before{
        width: 100%;
        height: 2px;
        background: $black;
        position: absolute;
        content: "";
    }
    &:after{
        width: 100%;
        height: 2px;
        background: $black;
        position: absolute;
        content: "";
    }
}

// tag Name
// Credits
.sd_film_artists_credits{

    .sd_film_artists_header{
        h2{
            @include defaultFontStyle(700, 18, 24, $black, -0.43px);  
        }
    }

    ul{
        margin-bottom: 35px;
        &:last-child{
            margin-bottom: 0;
        }
        @include mediaQuery($mobile) {
            margin-bottom: 26px;         
        }
    }
    li{
        @include flex;
        list-style: none;
        
        .sd_film_artists_cr_pos{
            @include defaultFontStyle(500, 18, 26, $black);   
            letter-spacing: -0.43px;  
            display: block;
            max-width: 226px;
            width: 100%;
            margin-right: 23px;
            @include flex_shrink;
            @include mediaQuery($large) {
                max-width: 120px;
            }
            @include mediaQuery($ipad) {
                max-width: 180px;
            }
            @include mediaQuery($mobile) {
                @include mediaFontStyle(13.61, 19.66);   
                letter-spacing: -0.33px;
                max-width: 154px !important;
            }
        }
        .sd_film_artists_cr_name{
            p, a{                
                @include defaultFontStyle(400, 18, 26, $black);   
                letter-spacing: -0.43px; 
                word-break: break-word;
                @include mediaQuery($mobile) {
                    @include mediaFontStyle(13.61, 19.66);   
                    letter-spacing: -0.33px;
                } 
            }
            .sd_credit_link_title{
                color: #1351dc;
                word-break: break-all;
            }
        }
    }
}


// Qa Live
.sd_qa_live{
    max-width: 775px;
    width: 100%;
    padding: 115px 44px 46px;
    background: #FFB30F;
    @include mediaQuery($extraLarge){
        max-width: 100%;
        margin-bottom: 30px;
        padding: 40px 20px 30px;
    }
    .sd_chat_content_wpr{
        &::-webkit-scrollbar {
            width: 8px;
            height: 8px;
            background: transparent;
        }
        &::-webkit-scrollbar-thumb {
            height: 13px;   
            background-clip: padding-box;
            border-radius: 4px;
            background-color: $yellow;
        }
    }
    &_title{
        margin-bottom: 20px;
    }
    &_content,.sd_qa_live_content{
        max-height: 355px;
        height: 100%;
        min-height: 355px;
        overflow: auto;
        // padding-right: 25px;
        &::-webkit-scrollbar {
            width: 8px;
            height: 8px;
            background: transparent;
        }
        &::-webkit-scrollbar-thumb {
            height: 13px;   
            background-clip: padding-box;
            border-radius: 4px;
            background-color: #cb8c00;
        }
        .sd_chat_content_wpr{
            padding-right: 13px;
        }
        .chat_message{
            // border: 2px solid #cb8c00;
            // min-height: 76px;
            .chat_user_data{
                @include flex_align_center;
                width: 100%;
                p{
                    @include defaultFontStyle(400, 14, 18, $black); 
                    width: calc(100% - 50px);
                }
                .like_icon{
                    padding: 5px;
                    margin-left: 20px;
                }
                &.active_moderator{
                    p{
                        color: $white;
                    }
                    .like_icon{
                        display: none;
                    }
                }
            }
        }

    }
    .sd_form_group{
        input{
            width: 100%;
            padding: 12px;
            border: 2px solid;
            @include defaultFontStyle(300,17,24,$black,0)
        }
    }
    .sd_chat_add{
        max-width: 96%;
    }
}