article, aside, details, figcaption, figure, footer, header, hgroup, main, nav, section, summary { display: block; }
body, ol, ul, li, div, p, span, form, h1, h2, h3, h4, h5, h6, table, thead, th, tbody, tr, td, img, input, textarea, dd, dt, dl { word-wrap: break-word; outline: none; padding: 0; margin: 0; -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }
textarea { overflow: auto; -webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale; }
h1, h2, h3, h4, h5, h6, p, ul, ol, dd, dt, dl { -webkit-margin-before: 0px; -webkit-margin-after: 0px; -webkit-margin-start: 0px; -webkit-margin-end: 0px; }
a img, img { border: none; outline: none; display: block; width: auto; max-width: 100%; }
a { cursor: pointer; text-decoration: none; outline: 0; }
input { -webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale; outline: none; }
html { font-size: 62.5%; } 
body { -webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale; -ms-font-smoothing: antialiased; background-color: $white; font-size: 62.5%; overflow-x: hidden; }
.no-scroll{ overflow: hidden !important; height: 100%;}
ul, menu, dir { margin: 0px; padding: 0px; display: block; list-style-type: disc; -webkit-margin-before: 0; -webkit-margin-after: 0; -webkit-margin-start: 0; -webkit-margin-end: 0; -webkit-padding-start: 0; }
* { -webkit-box-sizing: border-box; box-sizing: border-box;
    &:after, &:before {  -webkit-box-sizing: border-box; box-sizing: border-box; }
}
.clear { clear: both; height: 0px; overflow: hidden; width: auto; display: block; float: none !important; }
.no-script-msg { font: 12px Arial, verdana, Helvetica, sans-serif; background: $cream; width: auto; padding: 10px 10px 10px 25px; margin: 8px; border: 1px solid $red; font-weight: bold; height: auto; font-size: 11px; color: $black; line-height: 150%; clear: both; }
li:last-child, p:last-child { margin-bottom: 0 !important; }
img { -o-object-fit: cover !important; object-fit: cover !important;}
select{ outline: none; cursor: pointer; }
.sd_container{
    max-width: 1710px;
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
}
.sd_inner_container{
    max-width: 1520px;
    width: 100%;
    margin: 0 auto;
    padding: 0 20px;
}
.sd_sub_container{
    max-width: 990px;
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
}
.text_center{
    text-align: center;
}
.pointer{
    cursor: pointer;
}
.error{
    @include defaultFontStyle(400,12.5,24,$red,-0.3px)
}
.error_white{
    @include defaultFontStyle(400,12,16,$white,-0.29px)
}

// RTF content
body{
    
    .sd_rtf_content{
        p{
            @include defaultFontStyle(400, 16, 23, $black);
        }
        *{
            // font-family: 'Pangea' !important; 
            font-family: 'ABCMonumentGrotesk' !important; 
            background: transparent !important;
            font-size: 16px !important;
            color: $black !important;
        }
        p{
            padding-bottom: 10px;
            margin-left: 0 !important;
            &:last-child{
                padding-bottom: 0; 
            }
        }
        p, pre, li{
            @include white_space_pre;
            &:empty{
                padding-bottom: 17px;
            }
        }
        ul{
            li{
                list-style: disc;
                margin-left: 20px;
            }
        }
        ol{
            li{
                margin-left: 40px !important;
                list-style: decimal;
                &::marker{
                    width: 1px;
                }
            }
        }

        a{
            color: $purple !important;
            &:hover{
                text-decoration: underline;
            }
            *{
                color: $purple !important;
                &:hover{
                    text-decoration: underline;
                }
            }
        }

        em, i {
            font-style: italic !important;
            *{
                font-style: italic !important;
            }
        }
        strong, b {
            font-weight: bold !important;
            *{
                font-weight: bold !important;
            }
        }
        blockquote{
            padding-left: 20px;
            border-left: 5px solid $black;
            margin-left: 10px;
        }
    }
}

//Preloader
div#pre-loader { position: fixed; z-index: 99999; top: 0; bottom: 0; left: 0; right: 0; margin: AUTO; display: flex; justify-content: center; align-items: center; display: none; }
div#pre-loader::before { content: ""; background: #00000087; position: absolute; width: 100%; height: 100%; z-index: -1; }
div#pre-loader img { width: 100px; margin: auto; display: block;
    @media (max-width: 480px) { width: 200px; }
}