// Checkbox
.sd_filter_checkbox{
    position: relative;
    @include flex_align_center;
    margin-bottom: 18px;
    @include mediaQuery($large){
        margin-bottom: 14px;
    }
    input{
        display: none;
        &:checked {
            + label {
                &:after{
                    opacity: 1;
                }
            }
        }
    }
    label{
        @include defaultFontStyle(200, 18, 25, $black, -0.43px);
        font-family: "La Nord V2.1 Semi";
        position: relative;
        padding-left: 45px;
        cursor: pointer;
        @include mediaQuery($large){
            @include mediaFontStyle(16, 25);   
        }
        @include mediaQuery($ipad){
            @include mediaFontStyle(14.4, 18.4); 
            letter-spacing: -0.35px;  
            padding-left: 37px;
        }
        &:before{
            @include content;
            left: 0;
            top: 0;
            @include square(25px);
            background: $white;
            border: 2px solid $black;
            @include mediaQuery($ipad){
                @include square(20px);
            }
        }
        &:after{
            @include content;
            left: 5px;
            top: 5px;
            @include square(15px);
            @include transitionStyle(0.1, linear);
            background: url('/images/tick_icon.svg') no-repeat;
            background-size: contain;
            -webkit-transform: rotate(15deg);
                -ms-transform: rotate(15deg);
                    transform: rotate(15deg);
            opacity: 0;

            @include mediaQuery($ipad){
                left: 4px;
                top: 4px;
                @include square(12px);
            }
        }
    }
}