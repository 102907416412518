// Brought to you by
.sd_brought_by_section{
    // border-top: 2px solid $black;
    // border-bottom: 2px solid $black;
    .sd_brought_by_sec{
        text-align: center;
        padding: 122px 0 138px;
        @include mediaQuery($ipad){
            padding: 50px 0;
        }  
        p{
            @include defaultFontStyleScreening($font_family_normal, 13, 20, $black, -0.2px);
            font-weight: 400;
            margin-bottom: 24px;
        }
        img{
            max-width: 200px;
            margin: 0 auto;
            width: 100%; 
        }
    }
}