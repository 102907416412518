/* Display */
.sd_block { display: block; }
.sd_inlineblock { display: inline-block; }
.sd_hidden { display: none; }

/* Pointer */
.sd_p_cursor{ cursor: pointer; }
.sd_events_none { pointer-events: none; }

// Border
.border_none { border: 0 !important; }

// Opacity
.opacity_0 {
    opacity: 0;
}

/* Flex */
.sd_flex { @include flex; }
.sd_flexwrap { @include f_wrap; }
.sd_aligncenter { @include align_center; }
.sd_justbetween { @include justify_between; }
.sd_justcenter { @include justify_center; }

/* Position */
.sd_relative { position: relative; }
.sd_absolute { position: absolute; }

/* Text Alignment */
.sd_textcenter { text-align: center; }
.sd_textleft { text-align: left; }
.sd_textright { text-align: right; }

/* Text Formatting */
.sd_textuppercase { text-transform: uppercase; }
.sd_textlowercase { text-transform: lowercase; }
.sd_textcapitalize { text-transform: capitalize; }
.sd_flex_1_auto {flex: 1 1 auto;}