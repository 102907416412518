// Newsletter
.sd_newsletter_sec{
    background: $lighterGrey;
    // padding: 125px 0 80px;
    padding: 94px 0 88px;
    @include mediaQuery($ipad){
        padding: 127px 9px 115px;
    }
    @include mediaQuery(600px){
        padding: 50px 0;
    }
    .sd_inner_container{
        @media (min-width: 1600px) and (max-width: 1680px){
            &{
                max-width: 100%;
                padding: 0 20px 0 99px; 
            }
        }
        @include mediaQuery(480px){
            padding: 0 15px;
        }
    }
    h2{
        @include defaultFontStyleScreening($font_family_bold,40, 40, $black, 0.2px);   
        font-weight: 700;
        max-width: 460px;
        width: 100%;
        margin-bottom: 12px;
        text-transform: unset;
        @include mediaQuery($large){
            @include mediaFontStyle(36, 45);
            letter-spacing: -0.86px;
        }
        @include mediaQuery(600px){
            @include mediaFontStyle(32, 38);
        }
        @include mediaQuery($smallMobile){
            @include mediaFontStyle(26, 30);
        }
    }

    .sd_newsletter_form_inner{
        @include flex_dir_column;
        .sd_form_group{
            align-items: center;
            @include mediaQuery(360px){
                &{
                    flex-direction: column;
                    align-items: start;
                    input{
                        margin-right: 0;
                    }
                    .sd_submit_group{
                        max-width: 100px;
                        width: 100%;
                        button{
                            min-width: unset;
                            margin-top: 10px;
                        }
                    }
                }
            }
        }
        .srv-validation-message{
            color: #FF625A;
            @include defaultFontStyle(400, 14, 26, #FF625A);
        }
        .sd_input_error_msg{
            @include defaultFontStyle(400, 16, 26, $pink, -0.29px);
            .srv-validation-message{
                margin: 0;
            }
        }
    }

    .sd_form_group{
        // max-width: 652px;
        // width: 100%;
        
        input{
            background: $white;
            border: none;
            outline: none;
            @include defaultFontStyleScreening($font_family_normal,22, 32, $black, 0.2px);   
            font-weight: 400;
            padding: 10px 33px 10px;
            width: 100%;
            // font-family: "La Nord V2.1 Semi";
            margin-right: 20px;
            max-width: 472px;
            background-color: transparent;
            border: 1.5px solid #000000;
            @include mediaQuery($ipad){
                @include mediaFontStyle(18, 30);
            }
            @include mediaQuery($mobile){
                @include mediaFontStyle(16, 26);
                padding: 8px 10px 7px;
                letter-spacing: -0.38px;
                margin-right: 7.78px;
            }
            &::placeholder{
                color: rgba(0, 0, 0, 0.5);
            }
        }
    }
    .sd_form_radio_group{
        margin-top: 3px;
        input{
            opacity: 0;
            @include square(0);
            padding: 0;
            &:checked {
                + label {
                    &::before{
                        content: '\2713';
                        position: absolute;
                        left: 2px;
                        top: 5px;
                        z-index: 1;
                        @include defaultFontStyle(300, 14, 14, $black);
                    }
                }
            }
        }
        label{
            @include defaultFontStyle(200, 12, 23, $black);
            position: relative;
            font-family: "La Nord V2.1 Semi";
            padding-left: 22px;
            display: block;
            cursor: pointer;
            @include mediaQuery($mobile){
                @include mediaFontStyle(10, 23);
                letter-spacing: -0.24px;
            }
            &:after{
                @include content;
                left: 0;
                top: 3px;
                background: $white;
                @include square(14px);
            }
        }
    }

    .sd_submit_group{
        // max-width: 160px;
        // width: 100%;
        @include mediaQuery($mobile){
            max-width: 77px;
        }
        button{
            width: 100%;
            min-width: 185px;
            text-transform: uppercase;
            // padding: 16px 10px 11px;
            padding: 14px 30px;
            background-color: #000000;
            border-radius: 33px;
            border-color: $black;
            @include defaultFontStyleScreening($font_family_medium,18, 24, $white, -0.432px);   
            font-weight: 400;
            cursor: pointer;
            @include mediaQuery($ipad){
                @include defaultFontStyleScreening($font_family_medium,18, 30, $white, -0.432px);
                padding: 10px 20px;
            }
            @include mediaQuery($mobile){
                min-width: 77px;
                @include mediaFontStyle(14.33, 26);
                padding: 5px 5px 6px;
            }
            &:hover{
                background-color: transparent;
                color: $black !important;
            }
        }

    }
    .sd_input_msg{
        display: block;
        margin-top: 5px;
        @include defaultFontStyle(400, 14, 16, $pink, -0.29px);
        // Success Msg
        &.sd_input_success_msg {
            @include defaultFontStyle(400, 16, 26, $black, -0.29px);
        }
    }    
    .sd_newsletter_checkbox{
        margin-top: 18px;
        display: block;
        label{
            @include defaultFontStyleScreening($font_family_medium,12, 16, $black, -0.232px);   
            font-weight: 400;
            text-transform: uppercase;
            display: flex;
            position: relative;
            padding-left: 22px;
           
            input{
                height: 14px;
                width: 14px;
                appearance: none;
                position: absolute;
                left: 0;
                top: 2px;
                z-index: 99;
            }
            span.checkmark{
                    position: absolute;
                    top: 2px;
                    left: 0;
                    width: 14px;
                    height: 14px;
                    background-color: #fff;
                    z-index: 1;

                &::after { content: ''; left: 0; top: 1px; right: 0; width: 6px; height: 10px; border: solid black; border-width: 0 2px 2px 0; -webkit-transform: rotate(45deg); -ms-transform: rotate(45deg); transform: rotate(45deg); display: none; position: absolute; margin: 0 auto; z-index: 1; }
            }
            input:checked ~ .checkmark::after{
                display: block;
            }
        }
    }    
}

.srv-validation-message, .suggession_text {
    @include defaultFontStyleScreening($font_family_normal,13, 13, $white, -0.288px);
    display: block;
    margin-top: 10px;
    // text-transform: uppercase;
}   
.sd_create_form .suggession_text {
    max-width: 249px;
    width: 100%;
}   
// Success Msg
.sd_input_success_msg {
    display: block;
    @include defaultFontStyle(400, 16, 26, $black, -0.29px);
    margin-top: 5px;
}

.sd_input_error_msg{
    @include defaultFontStyle(400, 16, 26, $pink, -0.29px);
}