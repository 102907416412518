// Popup
.sd_popup{
    position: fixed;
    top: 0;
    left: 0;
    @include square(100%);
    background: rgba($black, 0.1);
    z-index: 999;
    overflow: hidden;

    .sd_popup_inner{        
        max-width: 537px;
        margin: 0 auto;
        width: 100%;
        max-height: 100%;
        background: $white;
        // box-shadow: 0 2px 4px 0 rgba($black, 0.5), 3px 7px 12px 5px rgba($black, 0.24);
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%,-50%);
        overflow: auto;
        border: none;
    }

    
    // Close Popup
    .sd_close_popup{
        position: absolute;
        top: 25px;
        right: 25px;
        @include square(28px);
        @include buttonReset;
        @include flex_justify_center;
        
        span{
            display: block;
            width: 2px;
            background: $black;
            height: 37px;
            &:first-child{
                transform: rotate(-45deg) translate(4px, -3px);
            }
            &:last-child{
                transform: rotate(45deg) translate(-4px, -3px);
            }
        }
    }
    
}

.sd_custom_popup{
    .sd_popup_inner{    
        max-width:700px;
        border-radius: 25px;
        @include mediaQuery(1120px){
            width: 95%;
        }
        .sd_popup_header
        {
            text-align: center;
            max-width: 500px;
        }
    }
}

// Verify Age popup
.sd_verify_age_popup{

    .sd_films_content_warning{
        // text-align: left;
        padding: 0;
        margin-bottom: 22px;
        p{
            @include defaultFontStyleScreening($font_family_medium, 18, 30, #FF00EA, -0.432px);
            font-weight: 400;
            text-transform: uppercase;
        }
    }
    h2{
        @include defaultFontStyleScreening($font_family_medium, 18, 24, $black, -0.432px);
        font-weight: 400;
        text-transform: uppercase;
    }

    .sd_popup_inner{    
        padding: 60px 20px 38px; 
        text-align: -webkit-center;
        @include mediaQuery($large){
            padding: 50px 40px 80px;
        }
        @include mediaQuery($ipad){
            padding: 50px 20px 40px;
        }
        .sd_popup_header_desc{
            @include defaultFontStyle(400, 18, 25, $black, -0.43px);
            @include mediaQuery($ipad){
                @include mediaFontStyle(16, 22);
            }
            .dropdown_date{
                @include flex_justify_center;
                margin: 28px 0 20px 0;
                @include mediaQuery($ipad){
                    @include flex_dir_column;
                    @include align_center;
                }
                select{
                    // border: none;
                    @include defaultFontStyleScreening($font_family_medium, 15, 19, $black, -0.36px);
                    width: 204px;
                    padding: 10px 45px 10px 30px;
                    border: 1px solid;
                    text-transform: uppercase;
                    -webkit-appearance: none;
                    background: url('./../../images/drop_down.svg') no-repeat;
                    background-position: 94%;
                    @include mediaFontStyle(18,23);
                    text-align-last: center;
                    @include mediaQuery($ipad){
                        @include mediaFontStyle(18, 22);
                    }
                    @include mediaQuery($mobile){
                        @include mediaFontStyle(16, 22);
                        width: 240px;
                    }
                }
                &_inner{
                    &:first-child{
                        select{
                            width: 297px;
                        }
                    }
                }
            }
        }
    }

    .sd_verify_age_confirm{
        text-align: center;
        padding-bottom: 40px;
        @include mediaQuery($ipad){
            padding-bottom: 30px;
        }
        label{                
            @include defaultFontStyleScreening($font_family_medium, 15, 19, $black, -0.36px);
            font-weight: 400;
            text-transform: uppercase;
            cursor: pointer;
            position: relative;
            padding-left: 35px;
            @include mediaQuery($ipad){
                @include mediaFontStyle(16, 22);
            }

            &:before{
                @include content;
                left: 0;
                top: 0;
                @include square(25px);
                background: $white;
                border: 1px solid $black;
            }
            &:after{
                @include content;
                left: 6px;
                top: 5px;
                @include square(15px);
                @include transitionStyle(0.1, linear);
                background: url('./../../images/tick_icon.svg') no-repeat;
                background-size: 12px;
                transform: rotate(15deg);
                opacity: 0;
            }
        }   
        input{
            display: none;
            &:checked {
                + label {
                    &:after{
                        opacity: 1;
                    }
                }
            }
        }
    }
    
    
}

.sd_link_btn{
    @include flex_align_center;
    @include defaultFontStyleScreening($font_family_medium, 18, 24, $black, -0.432px);
    text-transform: uppercase;
    // @include mediaQuery($ipad){
    //     @include mediaFontStyle(20, 22);
    // }
    img{
        width: 18px;
        margin-left: 8px;
    }
    &_pink{
        color: $black;
    }
}

// Watch Auduio popup
.sd_audio_desc_popup{
    .sd_popup_inner{
        padding: 48px 70px 100px;
        @include mediaQuery($desktop){
            padding: 70px 50px;
        }
        @include mediaQuery($large){ 
            @include mediaFontStyle(30, 40);
            padding: 70px 40px 20px;
        }
        @include mediaQuery($ipad){ 
            padding: 70px 20px 20px;
        }
    }

    .sd_popup_header{
        margin-bottom: 40px;
        @include mediaQuery($large){ 
            margin-bottom: 20px;
        }
        h2{
            @include defaultFontStyle(400, 60, 56, $black, -1.44px);
            max-width: 620px;
            @include mediaQuery($desktop){
                @include mediaFontStyle(50, 56);
            }
            @include mediaQuery($large){ 
                @include mediaFontStyle(40, 50);
            }
            @include mediaQuery($ipad){ 
                @include mediaFontStyle(30, 40);
                max-width: 100%;
            }
            @include mediaQuery($mobile){ 
                @include mediaFontStyle(26, 36);
            }
        }
    }

    .sd_popup_header_desc{
        @include flex;
        @include mediaQuery($large){
            @include flex_dir_column;
        }
    }
    .sd_audio_desc_block{
        max-width: 50%;
        width: 100%;
        text-align: center;
        border-right: 2px solid $black;
        padding: 0 20px 20px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        &:last-child{
            border-right: 0;
            @include mediaQuery($large){ 
                border-bottom: 0;
                padding: 0 20px 20px;
            }
    
        }
        @include mediaQuery($large){
            max-width: 100%;
            border-right: 0;
            border-bottom: 2px solid $black;
            padding: 0 20px 40px;
        }

    }
    .sd_audio_desc_block_img{
        padding: 30px 0 50px;
        @include mediaQuery($ipad){ 
            padding: 20px 0 30px;
        }
        img{
            margin: 0 auto;
            @include mediaQuery($ipad){
                width: 60px;
            }
        }
    }
}

// Rating popups
.sd_rating_popup{
    .sd_popup_sec{
        @include flex;
        @include mediaQuery($ipad){
            @include flex_dir_column;
        }
    }

    .sd_popup_rating_header{
       margin-bottom: 34px;
        h3{
            @include defaultFontStyle(400, 26, 32, $black, -0.62px);
            padding-bottom: 17px;
            @include mediaQuery($mobile){
                @include mediaFontStyle(22, 30);
            }
        }
        p{
            @include defaultFontStyle(700, 22, 27, $black, -0.38px); 
        }
        span{
            @include defaultFontStyle(700, 22, 27, $black, -0.38px); 
        }
    }

    .sd_popup_sec_wpr{
        max-width: 50%;
        width: 100%;
        padding-top: 135px;
        padding-bottom: 150px;
        @include mediaQuery($ipad){
            max-width: 100%;
            padding: 60px 40px !important; 
        }
        @include mediaQuery($mobile){ 
            padding: 50px 20px !important; 
        }
        .sd_popup_link_wpr
        {
            text-align: -webkit-center;
            .sd_link_btn{
                border: 2px solid;
                max-width: 217px !important;
                padding: 10px;
                border-radius: 25px;
                margin-top: 10px;
                @include defaultFontStyleScreening($font_family_medium, 13, 24, $white);
                font-weight: 400;
                text-transform: uppercase;
                background-color: $black;
                &:hover{
                    background-color: $white;
                    color: $black;
                }
                // @include mediaQuery($desktop){
                //     @include mediaFontStyle(55, 55);
                // }
                // @include mediaQuery($large){
                //     @include mediaFontStyle(46, 50);
                // }
                // @include mediaQuery($ipad){
                //     @include mediaFontStyle(36, 40);
                // }
                // @include mediaQuery($mobile){
                //     @include mediaFontStyle(26, 30);
                // }
            }
        }
    }

    .sd_popup_rating{
        border-right: 2px solid $black;
        padding-left: 65px;
        padding-right: 65px;
        @include mediaQuery($desktop){
            padding: 100px 40px; 
        }
        @include mediaQuery($ipad){
            border-right: 0;
            border-bottom: 2px solid $black;
        }
        
        .sd_popup_rating_wpr{
            overflow: hidden;
            > span{
                > span{
                    margin-right: 20px;
                    svg{
                        @include mediaQuery($large){
                            width: 35px;
                        } 
                        @include mediaQuery($mobile){
                            width: 30px;
                        }
                        @include mediaQuery($smallMobile){ 
                            width: 25px;
                        }

                    }
                }
            }
            p{
                @include defaultFontStyle(500, 22, 32, $pink, -0.53px);
                @include mediaQuery($large){
                    @include mediaFontStyle(20, 30);
                } 
                @include mediaQuery($mobile){
                    @include mediaFontStyle(18, 28);
                }
            }
        }
    }
    .sd_popup_sponsored_wpr{
        @include flex_align_center;
        padding: 40px 0 10px;
        p{
            @include defaultFontStyle(400, 10, 20, $black, -0.24px);
            margin-right: 10px;
        }
        img{
            max-width: 120px;
            width: 100%;
            @include flex_shrink;
        }
    }
    .sd_popup_right_sec{
        padding-left: 100px;
        padding-right: 100px;
        @include mediaQuery($desktop){
            padding: 100px 40px; 
        } 
    }
}

// Short Popup
.sd_short_popup{
    background: rgba(0,0,0,.4);
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 9999;
    .sd_popup_inner{
        max-width: 700px;
        border-radius: 25px;
        @include mediaQuery($large){
            width: 95%;
        } 
        text-align: center;
    }
    .sd_popup_sec_wpr{
        max-width: 100%;
        padding: 50px 00px 0px;
        
        .sd_link_btn{
            max-width: 100%;
            @include flex_justify_center;
        }
    }
}