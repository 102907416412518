
// Slick slider dots
.slick-slider{

    .slick-dots{
        bottom: -35px; 
        li{
            border: 1px solid $black;
            border-radius: 50%;
            @include transitionStyle(0.1, linear);
            @include square(16px);
            margin: 0 9px;
            display: inline-block;
            @include mediaQuery($ipad){
                @include square(12px);
                margin: 0 5px;
            }         
    
            &.slick-active{
                background: $black;
            }
        }
        button{
            padding: 0;
            @include square(16px);
            &:before{
                content: none;
            }
        }
    }
}



// Video Player
.controls_custom {
    height: 38px !important;
}
.sd_video_wpr{ 
    &:hover{
        .video-react-control-bar {
            transform: translateY(0) !important;
            &.video-react-control-out{
                transform: translateY(100%) !important;
            }
        }
    }
    .video-react-big-play-button{
        border: none !important;
        background: none !important;
        height: 90px !important; 
        margin: 0 !important;
        transform: translate(-50%, -50%);
        z-index: 5;
        @include mediaQuery($mobile){
            height: 50px !important; 
            width: 50px !important; 
        }
        &::before{
            background: url("../../images/video_play_icon.svg") no-repeat;
            content: "" !important;
            @include mediaQuery($mobile){
                background-size: 50px 50px;
            }
        }
    }

    .video-react { 
        padding-bottom: 56.25% !important;
        padding-top: 0 !important;
        &.video-react-user-inactive{
            .video-react-control-bar {  
                transform: translateY(100%) !important;
            }

        }
        &.video-react-paused{
            .video-react-big-play-button{
                display: block;
            }
        }
        .video-react-control-bar {
            padding: 0 16px 0 7px;
            transition: 0.3s linear;
            transform: translateY(100%);
            @include mediaQuery($ipad){
                padding: 0 10px 0 0;
            }
        }
        .video-react-play-control.video-react-playing{
            &:before {
                background: url("https://d2br0tbrfcvkl6.cloudfront.net/img/pause_btn.svg") no-repeat;
                content: "" !important;
                background-size: 24px;
                background-position: center center;
                @include mediaQuery($large){
                    background-size: 22px;
                }
            }
        }
        .video-react-play-control.video-react-paused{
            &:before {
                background: url("https://d2br0tbrfcvkl6.cloudfront.net/img/play_btn.svg") no-repeat;
                content: "" !important;
                background-size: 24px;
                background-position: center center;
                @include mediaQuery($large){
                    background-size: 22px;
                }
            }
        }
        .video-react-volume-menu-button{
            &:before {
                background: url("../../images/volume_btn.svg") no-repeat;
                content: "" !important;
                background-size: 18px;
                background-position: 3px 10px;
                @include mediaQuery($large){
                    background-size: 16px;
                    background-position: 3px 12px;
                }
            }
        }
        .video-react-slider {
            &:focus{
                box-shadow: none !important;
            }
        }
      
        .video-react-vol-muted {
            &:before {
                // background: url("https://d2br0tbrfcvkl6.cloudfront.net/img/mute_btn.svg") no-repeat;
                background: url("../../images/mute_btn.svg") no-repeat;
                content: "" !important;
                background-size: 20px;
                background-position: 3px 10px;
                @include mediaQuery($large){
                    background-size: 18px;
                    background-position: 3px 12px;
                }
            }
        }
        
        .video-react-volume-level, .video-react-play-progress {
            background-color: $activeBg !important;
        }
        .video-react-time-control {
            font-size: 11px !important;
            font-family: 'La Nord V2.1';
            @include flex_align_center;
        }
    
        .video-react-menu-button-inline:hover .video-react-menu,
        .video-react-menu-button-inline:focus .video-react-menu,
        .video-react-menu-button-inline .video-react-menu-content {
            display: flex !important;
            @include align_center;
        }
    
        .video-react-fullscreen-control {
            width: 150px !important;
            @include mediaQuery($large){
                width: 140px !important;
            }
            @include mediaQuery(576px){
                width: 30px !important;
            }
        }
        .video-react-icon-fullscreen-exit {
            width: 120px !important;
            @include mediaQuery($large){
                width: 100px !important;
            }
            @include mediaQuery(576px){
                width: 30px !important;
            }
        }
        .video-react-icon-fullscreen:before,
        .video-react-icon-fullscreen-exit:before {
            background: url("https://d2br0tbrfcvkl6.cloudfront.net/img/zoom_btn.svg") no-repeat;
            content: "Full Screen" !important;
            background-size: 19px;
            background-position: right;
            font-family: 'La Nord V2.1';
            color: $activeBg;
            font-size: 14px !important;
            line-height: 40px !important;
    
            @include mediaQuery($large){
                background-size: 15px;
                font-size: 12px !important;
                line-height: 40px !important;
            }
            @include mediaQuery(576px){
                content: "" !important;
                background-position: center;
            }
        }
        .video-react-icon-fullscreen-exit:before {
            content: "Exit" !important;
            @include mediaQuery(576px){
                content: "" !important;
                background-position: center;
            }
        }
    }
}