
.home{
    background: url('../../images/sff_banner_bg.png') no-repeat;
    background-size: cover;
    background-position: right;
    // @include border_bottom_black;
    // margin-top: -2px;
    @include mediaQuery($desktop){ 
        background-position: right;
        // background-size: 120% 100%;
    }      
    // @include mediaQuery($large){
    //     background: url('../../images/mobilebg.png') no-repeat;
    //     background-size: cover;
    //     background-position: right;
    // }
}
// Sign in and Register Form
.sd_user_form_section{ 
    .sd_inner_container{
        padding: 140px 20px 248px;
        @include mediaQuery(1680px){
            padding: 140px 20px 248px 140px;
        }
        @include mediaQuery(1600px){
            padding: 150px 20px 150px 80px;
        }
        @include mediaQuery(991px){
            padding: 80px;
        }
        @include mediaQuery(600px){
            padding: 80px 20px;
        }
    }  
}
.register{
    .sd_inner_container{
        @include mediaQuery(1450px){
            padding: 40px 15px;
        }
    }
}
.reset-password{
    .sd_inner_container{
        @include mediaQuery($desktop){
            padding: 100px 15px;
        }
    }

}
.sd_user_form{
    max-width: 350px;
    width: 100%;
    @include mediaQuery($mobileSmall){
        width: 100%;
        max-width: unset;                  
    }
    @include mediaQuery(600px){
        margin: 0 auto;         
    }
    button{
        @include buttonReset;
    }
    // Header
    .sd_user_form_header{
        //padding-bottom: 15px;
        h3{
            @include defaultFontStyle(700, 22, 27, $white, 0.5px); 
            max-width: 285px;  
            width: 100%;
            margin-bottom: 18px; 
            @include mediaQuery(991px){
                @include mediaFontStyle(20, 25);   
            }
            @include mediaQuery($ipad){
                max-width: 100%;
                @include mediaFontStyle(18, 24);               
            }         
            @include mediaQuery($mobile){
                @include mediaFontStyle(22, 27);
                max-width: 285px;
                width: 100%;          
            }     
        
        }
        // &.sd_signin_form_header{
        // }
        &.sd_reset_form_header{
            padding-bottom: 30px;
        }
        &.sd_create_form_header{
            padding-bottom: 40px;
            h3{
                margin-bottom: 0;
                text-transform: capitalize;
                letter-spacing: 0.8px
            }
        }
    }

    .sd_form_group_half{
        @include flex_justify_between;
        @include mediaQuery($ipad){
            @include f_wrap;      
        }         
        .sd_form_group{
            max-width: calc(50% - 7px);
            @include mediaQuery($ipad){
                max-width: 100%;    
                width: 100%;           
            }         
        }

    }
    
    // Inputs
    .sd_form_group{
        // padding: 10px 0;
        margin: 0 0 20px;
        position: relative;
        // Label
        label{
            @include defaultFontStyleScreening($font_family_medium,12, 15, $white, 0.2px);   
            font-weight: 400;
            margin-bottom: 6px;
            text-transform: uppercase;  
            display: block;        
        }
        &.sd_form_pwd_group{
            .field_input{
                margin-bottom: 0;
            }
        }
        .field_input{
            width: 100%;
            position: relative;
            margin: 0;
            .sd_passoword_toggle{
                position: absolute;
                top: 18px;
                right: 12px;
                img{
                    filter: brightness(0) invert(1);
                    width: 16px;
                    height: 8px;
                }
            }
        }
        input{
            display: block;
            width: 100%;
            border: 1.5px solid $white;
            outline: none;
            @include defaultFontStyle(200, 16, 20, $white, 0.2px);   
            background: transparent;
            padding: 9px 15px;
            &[type="password"]{
                padding: 9px 30px 9px 15px;
            }
        }

    }

    // Checkbox
    .sd_form_checkbox_group{
        margin: 4px 0;
        
        .checkbox_field_row {
            margin-bottom: 30px;
            @include mediaQuery(479px){
                &{
                    margin-bottom: 20px;
                  }
            }
            .ac_checkBox{
                display: flex;
                align-items: center;
            }
        }
        label{
            position: relative;
            @include defaultFontStyle(200, 10, 20, $black, -0.24px); 
            cursor: pointer;
            padding-left: 22px;
            @include mediaFontStyle(10,18);
            &::before{
                @include content;
                top: 2px;
                left: 0;
                @include square(12px);
                border: 1px solid $black;     
                // background: $white;           
            }
            &::after{
                @include content;
                left: 3px;
                top: 6px;
                transform: rotate(-45deg);
                width: 6px;
                height: 3px;
                border: 1px solid $black; 
                border-top: transparent;
                border-right: transparent;
                opacity: 0;
            }  
        }
        input{
            opacity: 0;
            position: absolute;
            &:checked{
                & ~ label{
                    &:after{
                        opacity: 1;
                    }
                }
            }
        }
    }


    .sd_form_link{
        text-align: right;
        button,.link_btn{ 
            @include defaultFontStyleScreening($font_family_medium,14, 18, $white, -0.336px); 
            font-weight: 400;
            // margin-top: -5px; 
            margin-top: 11px; 
            display: block;
            text-transform: uppercase;
        }
    }


    .sd_form_submit_note{
        margin-left: 18px;
        max-width: 156px;
        width: 100%;
        @include defaultFontStyleScreening($font_family_medium, 10, 10, $white);
        font-weight: 400;
        display: inline-block;
        text-transform: uppercase;
            a{
                text-decoration: underline; 
                @include defaultFontStyleScreening($font_family_medium, 10, 10, $white);
                font-weight: 400;
                width: 100%;
                // display: inline-block;
            }
    }

    .sd_form_options{
        padding-top: 6px;
        i{
            @include defaultFontStyle(300, 20, 30, $black, -0.48px);  
            font-style: italic;
        }
        .sd_foem_sign_link{
            // @include flex_align_center;
            @include defaultFontStyle(500, 18, 30, $black, -0.43px); 
            cursor: pointer;
            margin-left: 5px;
            svg{
                margin-left: 10px;
                width: 14px;
                color: $black;
                vertical-align: middle;
            }
        }
        
    }
}
.banner_display_contant{
    display: none;
    margin-bottom: 25px;
    h2{
        color: $darkGreen;
        @include mediaQuery($ipad){
            @include mediaFontStyle(24,36)
        } 
        @include mediaQuery($mobile){
            @include mediaFontStyle(20,30)
        }
    }
    h1{
        @include defaultFontStyle(700,80,90,$black,-0.3px);
        color: $darkGreen;
        @include mediaQuery($ipad){
            @include mediaFontStyle(40,42)
        }
        @include mediaQuery($mobile){
            @include mediaFontStyle(30,40)
        }
    }
    @include mediaQuery($large){
        display: block;
    }
}
// Error Message
.sd_input_error_msg {
    display: block;
    @include defaultFontStyle(400, 12, 16, $pink, -0.29px);
    margin-top: 5px;

    &.text_white {
        color: $white;
    }
}

// Success Msg
.sd_input_success_msg {
    display: block;
    @include defaultFontStyle(400, 16, 26, $black, -0.29px);
    margin-top: 5px;
}

// Password Input
.sd_passoword_toggle{
    position: absolute;
    right: 8px;
    top: 55px; 
    background: transparent !important;
    min-width: auto !important;
    padding: 0 !important;
    &.sd_form_pwd_active{
        .sd_password_eye_close{
            display: block;   
        }
        .sd_password_eye_open{
            display: none;   
        }
    }
    .sd_password_eye_close{
        display: none;
    }
}

.sd_account_found{
    margin-right: -140px;
    @include mediaQuery($ipad){
        margin-right: 0;                
    }   
    p{
        @include defaultFontStyle(300, 14, 24, $black, -0.34px);
        font-style: italic;
    }
    a{
        @include defaultFontStyle(500, 14, 20, $pink, -0.34px);
    }
}

.sd_popup_form{
    &.sd_user_form{
        margin: auto !important;
    }
    .sd_forgot_password{
        .sd_form_group {
            input{
                color: $black;
            }
        }
    }
    input{
        background: #F7F7F7 !important;
    }
}

.sd_Create_an_Account
{
    text-transform: uppercase;
    i{
        @include defaultFontStyleScreening($font_family_medium,13, 24, $white, -0.312px); 
        font-weight: 400;
        margin-right: 5px;
        display: inline-block
    }
    .create_link_btn
    {
        display: inline-flex;
        align-items: center;
        @include defaultFontStyleScreening($font_family_medium,13, 24, $white, -0.312px); 
        font-weight: 400;
        svg{
            width: 14px;
            height: 18px;
            vertical-align: middle;
            margin-left: 9px;
            g{
                fill: $white;
            }
        }
    }
}
.sd_create_form_section{
  .sd_inner_container {
        padding: 76px 20px 142px;
        @include mediaQuery(1680px){
            padding: 76px 20px 142px 140px;
        }
        @include mediaQuery(1600px){
            padding: 76px 20px 142px 80px;
        }
        @include mediaQuery(991px){
            padding: 80px;
        }
        @include mediaQuery(600px){
            padding: 50px 20px;
        }
        .sd_create_form_header{
            @include mediaQuery(479px){
                &{
                    padding-bottom: 20px;
                    h3{
                        @include mediaFontStyle(22, 28)
                    }
                }
            } 
        }
    }
    form{
        .sd_form_group {
            // .field_input{
            //     margin: 0 0 16px !important;
            // }
            .field_row_inner{
                max-width: 280px;
                width: 100%;
                @include mediaQuery(1600px){ max-width: 100%; }
            }
        }
        .sd_form_group_half{
            max-width: 280px;
            width: 100%;
            @include mediaQuery(1600px){ max-width: 100%; }
        }
        .checkbox_field_row{
            .ac_checkBox{
                position: relative;
                input{
                    top: 2px;
                    left: 0;
                    width: 12px;
                    height: 12px;
                }
                label{
                    @include defaultFontStyleScreening($font_family_medium, 10, 10, $white);
                    font-weight: 400;
                    text-transform: uppercase;
                    padding-left: 20px;
                    max-width: 260px;
                    width: 100%;
                    &::before{
                        background: #D8D8D8;
                        border: 1px solid #FFFFFF;
                    }
                    &::after{
                        top: 5px;
                        width: 7px;
                        height: 4px;
                    }
                }
            }
        }
        .sd_form_submit{
            @include mediaQuery(479px){
                &{
                  flex-direction: column;
                  align-items: flex-start;
                  .sd_form_submit_note{
                    margin: 15px 0 0;
                    max-width: 100%;
                  }
                }
            } 
        }
    }
}
    // Submit
    .sd_form_submit{
        padding: 0 0 20px;
        @include flex_align_center;
        .sd_btn{
            border: 2px solid $white;
            @include defaultFontStyleScreening($font_family_medium,14, 40, $black, -0.3px); 
            max-width: 148px;
            width: 100%;
            background-color: #fff;
            text-transform: uppercase;
            border-radius: 44px;
            text-align: center;
            -webkit-appearance: none;
            -webkit-appearance: button;
            cursor: pointer;
            height: auto;
            line-height: 0;
            padding: 0;
            min-height: 44px;
            display: flex;
            justify-content: center;
            align-items: center;
            &:hover{
                background-color: transparent;
                color: $white !important;
            }
        }
        &.reset_btn{
            padding: 14px 0 27px;
        }
        &.sign_btn{
            // margin-top: -18px;
            margin-top: 5px;
            @include mediaQuery(480px) {
                margin-top: 10px;
            }
        }
    }


    