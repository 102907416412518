// Colors
$black: #000000;
$white: #FFFFFF;
$purple: #7758B3;
$darkGreen: #146E96;
$lightGreen: #65FADC;
$yellow: #FFB30F;
$yellowish: #FED105;
$pink: #FF625A;
$lightGrey: #C2C2C2;
// $lighterGrey: #C9C9C9;
$lighterGrey: #AEBEC3;
$darkGrey: #9A9A9A;
$switchBG: #F1F1F1;
$red: #E74C3C;
$lightRed: #FE766F;
$whisper: #ECECEC;
$trinidad: #D22846;
$nobel: #A2A2A2;
$gainsboro: #E1E0E0;
$whiteSmoke: #F6F5F5;
$cream: #FFFFCC;
$lynxWhite2:#F5F5F5;
$lynxWhite: #F7F7F7;
$lygreen:#04AC80;
$darkpink:#C01A7D;
$lightpink:#E4D5E3;

$activeBg:#FFCC09;
$activeborder:#CA8B02;
$btnbg:#f5f5f5;
$redpink:#FF4841;
// Breakpoints for Media Query
$smallMobile: 325px;
$mobileSmall: 375px;
$mobile: 479px;
$mobileLarge: 576px;
$ipad: 768px;
$large: 991px;
$extraLarge: 1024px;
$desktop: 1366px;

$font_family_pangea:'Pangea';
// $font_family_bold:'Pangea_bold';
// $font_family_medium:'Pangea_medium';
// $font_family_light:'Pangea_light';

$font_family_normal:'ABCMonumentGrotesk';
$font_family_medium:'ABCMonumentGroteskMono_medium';
$font_family_light:'ABCMonumentGrotesk_mono';
$font_family_bold:'ABCMonumentGrotesk_bold';