// Chat
.sd_live_seat_section{
    max-width: 538px;
    width: 100%;
    .crt-panel-prev {
        left: 40px;
    }
    .crt-panel-next {
        right: 40px;
    }
    .crt-panel-arrow{
        display: none;
    }
}
.sd_chat_section{
    max-width: 468px;
    width: 100%;
    padding-left: 34px;
    @include mediaQuery($desktop){
       max-width: 380px;
       width: 100%;
    }
    @include mediaQuery(991px){
       max-width: 100%;
       margin: 30px auto 0;
       padding-left: 0;
    }
    .sd_inner_container{
        max-width: 1710px;
        padding: 0px;
        // @include flex;
        align-items: flex-start;
        @include mediaQuery($extraLarge){
            @include flex_dir_column_rev;
        }   
    }
    .sd_chat_content{
        padding-right: 0px;
        @include mediaQuery($extraLarge){
            padding-right: 0;
        }   
    }
    .sd_chat_wpr{
        // border-top: 2px solid $black;
        max-width: 1052px;
        width: 100%;
        // padding-bottom: 180px;
        // margin-bottom: -65px;
        @include mediaQuery($extraLarge){
            margin-bottom: 0;
            border-right: 0;
            padding-bottom: 40px;
        }   

        .sd_chat_header{
            margin: 0px 0 16px;
            @include mediaQuery($ipad){
                margin: 0 0 15px;
            }   
            h2{
                @include defaultFontStyle(700, 22, 27, $black, 0.2px); 
                text-transform: capitalize;
                @include mediaQuery($ipad){
                    @include mediaFontStyle(18, 22); 
                }   
            }
        }
    }
    .sd_chat_content_wpr{
        margin: 14px 0 10px;
        height: 350px;
        overflow: auto; 
        padding-right: 8px;
        @include mediaQuery($ipad){
            padding-right: 8px;
        }
        &::-webkit-scrollbar {
            width: 8px;
            height: 8px;
            background: $white;
            @include mediaQuery($ipad){
                width: 4px;
            }
        }
        &::-webkit-scrollbar-thumb {
            height: 13px;   
            background-clip: padding-box;
            border-radius: 4px;
            background-color: $gainsboro;
        }
    }

    // chat with qa
    &.sd_chat_qa_wrapper{
        
        .sd_chat_wpr{
            max-width: 415px;
            width: 100%;
        }
        .sd_inner_container{
            @include mediaQuery($extraLarge){
                flex-direction: column;
            }
        }
    }
}

.sd_chat_add{
    // padding: 17px 23px 0 0;
    .sd_form_group{
        margin-bottom: 20px;
        textarea {
            border: 1.3px solid $black;
            @include defaultFontStyle(300, 18, 18, $black);  
            resize: none;
            width: 100%;
            padding: 10px 17px 15px;
            height: 94px; 
            @include mediaQuery($ipad){
                padding-top: 6px;
            }   
            @include mediaQuery($mobile){
                @include mediaFontStyle(18, 20); 
                height: 100px;
            }   
            &::placeholder{
                @include defaultFontStyle(300, 18, 18, #666); 
                @include mediaQuery($ipad){
                    @include mediaFontStyle(20, 30); 
                }   
            }
        }
    }

    .sd_btn_group{
        text-align: right;
        padding-bottom: 0;
        button{
            border: 2px solid #000000;
            color: #fff;
            background-color: #000;
            margin-left: auto;
            &:hover{
                background-color: transparent;
                color: $black !important;
            }
            // @include mediaQuery($ipad){
            //     max-width: 140px;
            // }   
            // @include mediaQuery($mobile){
            //     @include mediaFontStyle(20, 30); 
            //     padding: 10px 20px;
            // }   
        }
    }
}

// Seats
.sd_seats_section{
    border-bottom: 2px solid $black;
    position: relative;
    text-align: center;
    @include mediaQuery($extraLarge){
        width: 100%; 
        text-align: center;  
        border-bottom: 0;     
    }   
    &:after{
        @include content;
        border-bottom: 2px solid $black;
        width: 3000px;
        bottom: -2px;
        left: 100%;
        @include mediaQuery($extraLarge){
            display: none;
        }   

    }
    .sd_seats_header{
        padding: 20px 0;
        text-align: center;
        @include mediaQuery($ipad){
            padding-bottom: 0; 
        }   
        h2{
            @include defaultFontStyle(400, 32, 32, $black, -0.77px);            
            @include mediaQuery($ipad){
                @include mediaFontStyle(26, 40); 
            }   
            
        }
    }
    .sd_seats_wpr{
        padding: 20px 15px 20px 40px; 
        @include mediaQuery($ipad){
            padding: 20px; 
        }    
        @include mediaQuery($mobile){
            padding: 0 0 20px; 
        }  
    }
    .sd_seats_sec{
        padding: 20px 0 15px;
        @include mediaQuery($ipad){
            padding: 20px 0 0;
        }   
        svg{
            width: 375px;
            @include mediaQuery($ipad){
                width: 100%;
            }   
        }
    }
}
.sd_chat_hide{
    display: none;
}
.sd_live_qa_section{
    @include mediaQuery($large){
        width: 100%;
    }    
    .crt-panel-prev{
        left: 0;
    }
    .crt-panel-next{
        right: 0;
    }

    max-width: 678px;
    width: 100%;
    // padding: 115px 44px 46px;
    // background: #FFB30F;
    @include mediaQuery($extraLarge){
        max-width: 100%;
        margin-bottom: 30px;
        padding: 40px 00px 30px;
    }
    .sd_chat_content_wpr{
        &::-webkit-scrollbar {
            width: 8px;
            height: 8px;
            background: transparent;
        }
        &::-webkit-scrollbar-thumb {
            height: 13px;   
            background-clip: padding-box;
            border-radius: 4px;
            background-color: $yellow;
        }
    }
    &_title{
        margin-bottom: 20px;
    }
    &_content{
        .sd_chat_content_wpr{
            padding-right: 13px;
        }
        .chat_message{
            border: 2px solid $yellow;
            min-height: 76px;
            .chat_user_data{
                @include flex_align_center;
                width: 100%;
                p{
                    @include defaultFontStyle(400, 14, 18, $black); 
                    width: calc(100% - 50px);
                }
                .like_icon{
                    padding: 5px;
                    margin-left: 20px;
                }
                &.active_moderator{
                    p{
                        color: $white;
                    }
                    .like_icon{
                        display: none;
                    }
                }
            }
        }

    }
    .sd_form_group{
        input{
            width: 100%;
            padding: 12px;
            border: 2px solid;
            @include defaultFontStyle(300,17,24,$black,0);
            background: transparent;
            color: $black;
            height: 94px;
            &::placeholder{
                @include defaultFontStyle(400, 18, 40, #666); 
            }
        }
    }
    .sd_chat_add{
        max-width: 98.5%;
        
    }
}

.sd_chat-note{
    margin-top: 24px;
    text-align: center;
    font-style: italic;
    font-family: 'Pangea_light';
}