

// Chat Message
.chat_message{  
    @include flex_align_center;
    border: 1px solid $gainsboro;
    padding: 6px 10px 6px 7px;
    margin-bottom: 10px;
    // margin-top: 15px;
    // Image
    &:last-child{
        margin-bottom: 0;
    }
    .chat_user_img{
        position: relative;
        @include flex_shrink;
        margin-right: 10px;
        @include square(41px); 
        @include mediaQuery($ipad){
            @include square(45px); 
        }   
        img{
            position: absolute;
            top: 0;
            left: 0;
            @include square(100%); 
        }
    }  
    // Content
    .chat_user_data{
        width: calc(100% - 90px);
        padding-right: 10px;
        p{
           @include defaultFontStyle(400, 14, 30, $black, 0.2px);
            strong{
                @include defaultFontStyle(700, 14, 30, $black, 0.2px);
            }
            @include mediaQuery($ipad){
                @include mediaFontStyle(18, 26); 
            }   
            @include mediaQuery($mobile){
                @include mediaFontStyle(16, 24); 
            }        
        }
        .chat_message_like{
            @include flex_align_center;
            justify-content: flex-end; 
            width: 90px;
            span {
                @include defaultFontStyle(700, 16, 16, $black); 

            }
        }
    } 

    &.moderator_chat_message{
        p{
            strong{
                color: $lygreen;
            }
        }
        .chat_user_img{
            background: $lygreen;
            img{
                display: none;
            }
        }
    }
}