// Popup
.sd_popup{
    position: fixed;
    top: 0;
    left: 0;
    @include square(100%);
    background: rgba($black, 0.6);
    z-index: 999;
    overflow: hidden;
    
    // Close Popup
    .sd_close_popup{
        position: absolute;
        top: 31px;
        right: 36px;
        @include square(28px);
        @include buttonReset;
        @include flex_justify_center;
        
        span{
            display: block;
            width: 2px;
            background: $black;
            height: 37px;
            &:first-child{
                transform: rotate(-45deg) translate(4px, -3px);
            }
            &:last-child{
                transform: rotate(45deg) translate(-4px, -3px);
            }
        }
    }
    
    .sd_popup_inner{        
        border-radius: 25px;
        max-width: 537px;
        margin: 0 auto;
        width: 100%;
        max-height: 100%;
        background: $white;
        box-shadow: 0 0 15px 5px rgba($black, 0.15);
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%,-50%);
        overflow: auto;
        padding: 10px 40px 40px;
        // Five hr popup
        &.five_hr_popup{
          max-width: 700px;
          padding: 86px 38px 63px;
          height: auto;
          .title{
            @include defaultFontStyleScreening($font_family_normal,50,50,$black,-0.96px)
          }
          .btn_wrp{
            margin-top: 25px;
          }
          button{
            @include roundbutton;
            border: 2px solid;
            margin: 0 auto;
            padding-bottom: 9px;
            background-color: $black;
            color: $white;
            &:hover{
              background-color: $white;
              color: $black;
            }
          }
        }
    }
     
}
// Popup Header
.sd_popup_header{
    padding: 25px 0 0;
    h3{
        @include defaultFontStyleScreening($font_family_medium,30, 30, $black, -0.62px); 
        text-transform: uppercase;
    }
}

// Popup Content
.sd_popup_content{
    padding-top: 25px;

    p, pre{
        text-align: center;
        @include defaultFontStyleScreening($font_family_light,16, 20, $black, -0.38px);
        max-width: 410px;
        margin: 0 auto 15px;
        white-space: pre-wrap;

        @include mediaQuery($mobile){
            font-size: 14px;                 
        }     
    }

    .sd_input_error_msg{
        text-align: center;
        @include defaultFontStyle(500, 12.5, 24, $pink, -0.3px); 
    }
}

// Popup Button
.sd_popup_buttons{
    @include flex_center_center;
    padding-top: 13px;
    button{
        @include defaultFontStyle(500, 16.5, 16, $black, -0.4px); 
        max-width: 113px;
        width: 100%;
        padding: 17px 10px;  
        border: none;      
        cursor: pointer;
        @include transitionStyle(0.2, linear);
    }
    .sd_grey_btn{
        background: $black;
        color: $white;
        margin-right: 18px;
        border-radius: 30px;
        padding: 12px 10px;
        border: 2px solid #000;
        &:hover{
            background: transparent;
            color: $black;
        }
    }
    .sd_yellow_fill_btn{
        background: $yellow;
        border-radius: 30px;
        padding: 12px 10px;
        border: 2px solid $yellow;
        &:hover{
            background: $pink;
            border: 2px solid $pink;
        }
    }
    .sd_pink_fill_btn{
        background: $pink;
        &:hover{
            background: $yellow;
        }
    }
}
// WouldYouLike popup
.sd_Would_you_like_popup
{
  // @include mediaQuery($ipad) {
  // max-width: 340px;
  // width: 100%;
  // }
  .sd_popup_inner
  {
    padding: 40px 20px 81px;
    text-align: center;
    @include mediaQuery($ipad) {
      padding: 40px 20px 50px;
    }
    p{
      @include defaultFontStyleScreening($font_family_medium, 18, 24, $black, -0.43px);
      font-weight: 400;
      max-width: 500px;
      width: 100%;
      margin: 0 auto;
      text-transform: uppercase;
      @include mediaQuery($ipad) {
        @include mediaFontStyle(16,19);
      }
      @include mediaQuery($mobile) {
        @include mediaFontStyle(14,18);
      }
    }
    h2{
      @include defaultFontStyle(700, 40, 40, $black, -0.1px);
      margin: 32px auto 53px;
      max-width: 460px;
      width: 100%;
      @include mediaQuery($ipad) {
        @include mediaFontStyle(28,30);
        margin: 32px auto;
        max-width: 320px;
        width: 100%;
      }
      @include mediaQuery($mobile) {
        @include mediaFontStyle(24,26);
        margin: 32px auto;
      }
      span{
        color: #FF00EA !important;
      }
    }
    .sd_Would_you_btn{
      justify-content: center;
      @include flex_wrap;
      button{
        // @include roundbutton;
        max-width: 217px;
        width: 100%;
        border-radius: 50px;
        padding: 6px 0;
        text-transform: uppercase;
        cursor: pointer;
        @include defaultFontStyleScreening($font_family_medium, 15, 24, $white, -0.36px);
        font-weight: 400;
        margin: 5px 8px;
        &:hover{
          @include btnhover;
        }
      }
      .sd_btn_yes{
        border: 1.7px solid $black;
        background-color: $black;
       
      }
      .sd_btn_Not_yet{
        border: 2px solid #EBEBEB;
        background: #EBEBEB;
        // margin-left: 15px;
        color: $black;
        // @include mediaQuery($ipad) {
        //   margin-left: 0px;
        //   margin-top: 12px;
        // }
        span{
          // font-family: $font_family_normal !important;
        }
      }
    }
  }
}
