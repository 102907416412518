// Film video
// Video
.sd_video_screen_section{
    // margin: 35px 0;
    padding: 46px 0 50px; 
    .sd_inner_container{
        @media (min-width: 1600px) and (max-width: 1680px){
            max-width: 100%;
            padding: 0 100px;
        }
        @include mediaQuery(480px){  padding: 0 15px; }
    }
    .sd_video_screen_wpr_main
    {
        display: flex;
        justify-content: space-between;

        @include mediaQuery(991px){ flex-direction: column; }
    }
    .sd_video_screen_wpr
    {
        max-width: 1016px;
        width: 100%;
        @include mediaQuery(1670px){
            max-width: 100%;
            width: calc(100% - 468px);
        }
        @include mediaQuery($desktop){
            width: calc(100% - 380px);
         }
        @include mediaQuery(991px){
            width: 100%;
        }
        &.fullscreen
        {
            max-width: unset;
            width: 100%;
        }
    }

}

.sd_video_screen_desc{
    @include flex_justify_between;
    width: 100%;
    padding-top: 25px;
    align-items: flex-start;

    @include mediaQuery($extraLarge){
        @include flex_dir_column;
        @include justify_center;
    }
    .sd_video_link{
        a{
            @include flex_align_center;
            img{
                margin-top: 0;
            }
        }
    }
    .sd_video_audio_info{
        text-align: center;
        max-width: 840px;
        width: 100%;
        padding: 0 20px;
        @include mediaQuery($extraLarge){
            margin: 0 auto;
        }
        p{            
            @include defaultFontStyle(400, 16, 23, $black, -0.38px); 
            margin-bottom: 20px;
        }

    }
}
.sd_video_screen_link {
    @include flex_align_center;
    @include flex_shrink;
    max-width: 160px;
    width: 100%;
    margin-left: auto;
    text-align: start;
    @include mediaQuery($mobile) {
      margin-left: auto;
    }
    > img {
      margin-right: 13px;
      width: 50px;
    }

    a {
      display: inline-block;
      @include defaultFontStyleScreening($font_family_medium, 11, 16, $black, -0.43px);
      font-weight: 400;
      text-transform: uppercase;

      img {
        width: 16px;
        height: 14px;
        margin: 0 0 4px 10px;
        display: inline-block;
        vertical-align: middle;
      }
    }
  }
.sd_live_qa
{
    h4{
        max-width: 169px;
        width: 100%;
        margin: 0 auto;
        @include defaultFontStyle(400, 22, 24, $black, -0.43px); 
        text-align: end;
        @include mediaQuery($ipad)
        {
            max-width: 112px;
            width: 100%;
            @include mediaFontStyle(14, 16);
        }
    }
    .sd_video_audio{
        max-width: 190px;
        width: 100%; 
        margin-bottom:44px;
        &:empty{
            margin: 0;
        }
        @include mediaQuery($ipad){
            max-width: 138px; 
            width: 100%;
            margin-bottom:15px;
            // @include justify_center;
        }
        @include mediaQuery($mobileSmall){
            max-width: 128px; 
            width: 100%;
        }
    }
}
.sd_video_screen_link_sec{
    max-width: 180px;
}

// .sd_video_screen_wpr_main
// {
//     @include flex_wrap;
//     justify-content: space-between;
// }
// .sd_video_screen_wpr
// {
//     max-width: 1016px;
//     width: 100%;
// }
.sd_video_screen{
    position: relative;
    // max-width: 1016px;
    width: 100%;
    .sd_popup{
        @include minmediaQuery($large){
            position: absolute;
            background: transparent; 
            z-index: 1;
        }

        .sd_popup_inner{
            @include minmediaQuery($large){
                top: auto;
                bottom: 72px;
                left: auto;
                right: 15px;
                transform: translate(0,0);
                max-width: 814px;
            }
            @media screen and (max-height: 700px){
                height: auto;
            } 
        }
        
        .sd_popup_rating{
            padding-left: 22px;
            padding-right: 11px;
            @include mediaQuery($large){
                padding-left: 20px !important;
                padding-right: 20px !important;
            }
        }
        .sd_popup_right_sec{
            padding-left: 31px;
            padding-right: 30px;
            @include mediaQuery($large){
                padding-left: 20px !important;
                padding-right: 20px !important;
            }
        }
        .sd_close_popup{
            @include square(12px);
            top: 14px;
            right: 14px;
            span{           
                height: 17px;
            }
        }
    }

    .sd_rating_popup{
        .sd_popup_rating_header{ 
            padding-bottom: 28px;
            h3{
                @include defaultFontStyle(400, 20, 20, $black, -0.48px); 
                padding-bottom: 20px;
            }
            p{
                @include defaultFontStyle(400, 16, 19, $black, -0.38px); 
            }
        }
        .sd_popup_sec_wpr{
            padding-top: 30px;
            padding-bottom: 30px;
            .sd_link_btn{
                @include defaultFontStyle(400, 28, 28, $yellow, -0.67px); 
            }
        }
        .sd_popup_rating_sec{
            @include flex_center_between;
            @include mediaQuery($ipad){
                @include flex_dir_column;
                align-items: flex-start;
            }
            .sd_popup_sponsored_wpr{
                @include flex_dir_column;
                align-items: flex-start;
                padding: 0;
                @include mediaQuery($ipad){
                    padding-top: 20px;
                }
            }
        }
        .sd_popup_rating_wpr > span > span{
            margin-right: 13px;
            svg{
                width: 33px;
            }
        }
    } 

    .sd_short_popup{
        .sd_popup_inner{
            max-width: 424px;
        }
    }
}

.vid_wrapper{
    position: relative;
    padding-bottom: 56.25%; 
    padding-top: 25px;
    height: 0;
    iframe{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}
.player_wrap{
    overflow: hidden;
}