.film_card{
    margin-bottom: 50px;
    width: 50%;
    padding: 0 12.5px;
    @include mediaQuery($ipad){
        width: 50%;
        margin-bottom: 30px;
    }
    @include mediaQuery(600px){
        width: 100%;
        padding: 0;
    }
    .film_card_img{ 
        height: 400px;
        img{
            @include square(100%);
            border-radius: 32px;
        }
        @include mediaQuery(991px){
            height: 350px;
        }
        @include mediaQuery(380px){
            height: 190px;
        }
    }
    p{
        @include defaultFontStyleScreening($font_family_medium,18, 24, $black, -0.432px); 
        font-weight: 400;
        padding-top: 20px;
        text-transform: uppercase;
    }
    h3{
        @include defaultFontStyle(700, 40, 40, $black);  
        text-transform: unset;
        padding-top: 8px;
        padding-bottom: 14px;
        @include mediaQuery($ipad){
            @include defaultFontStyle(700, 24, 28, $black);  
        }
        @include mediaQuery($mobile){
            @include defaultFontStyle(700, 30, 30, $black);  
        }
    }
 
    span, a{ 
        @include defaultFontStyleScreening($font_family_medium,18, 24, #fff, -0.432px); 
        font-weight: 400;
        padding: 14px 10px 13px;
        display: block;
        max-width: 224px;
        width: 100%;
        border-radius: 33px;
        background-color: #000;
        text-transform: uppercase;
        text-align: center;
        cursor: pointer;
        border: 2px solid #000;
        &.disabled{
            background-color: rgba(0, 0,0, 0.5);
            color: rgba(255, 255, 255, 0.5);
        }
        @include mediaQuery($ipad){
            padding: 10px 21px;
            @include mediaFontStyle(18,24)
        }
        @include mediaQuery(480px){
            @include mediaFontStyle(16,22);
            display: inline-block;
            max-width: unset;
            width: auto;
        }
        &:hover{
            background-color: transparent;
            color: $black;
        }
    }
    a{
        &.Passed{
            border: 2px solid #7f7f7f;
            max-width: 224px;
        }
        &.watched{
            max-width: 185px;
            border: 2px solid #7f7f7f;
        }
        // @include defaultFontStyle(500, 22, 50, $black, -0.53px);  
        // border: 2px solid $black;
        // padding: 20px 31px;
        // @include mediaQuery($ipad){
        //     padding: 10px 21px;
        //     @include mediaFontStyle(18,24)
        // }
        // &.disabled{
        //     @include defaultFontStyle(500, 22, 50, $nobel, -0.53px);  
        // border: 2px solid $nobel;
        // }
        // &:hover{
        //     color: $white;
        //     background-color: black;
        // }
    }
}
