// footer
footer {
    background: $black;
    position: relative;
    // .sd_container{
    //     padding: 0;
    // }
    .sd_large_container{
        max-width: 1510px;
        width: 100%;
        margin: 0 auto;
        padding: 0 15px;
        @include mediaQuery(1680px){
            &{
                max-width: 100%;
                padding: 0 0 0 100px;
            }
        }
        @include mediaQuery(1600px){
            &{
                padding: 0 0 0 20px;
            }
        }
        @include mediaQuery(479px){
            padding: 0;
        }
        .sd_footer_wpr{
            margin-left: auto;
            @include mediaQuery(1710px){
                overflow-x: hidden;
            }
            @include mediaQuery($extraLarge){
                @include flex_dir_column;
                padding: 20px 0;
            }
            @include mediaQuery($mobile){
                padding: 0;
                .sd_flex_1_auto{
                    width: 100%;
                    padding:20px 0 0 15px;
                }
            }
        }
        .sd_flex_1_auto{
            @include mediaQuery($extraLarge){
                // padding: 0 100px;
            }
            @include mediaQuery($large){
                padding: 0;
            }
            @include mediaQuery($mobileLarge){
                display: block;
            }
            @include mediaQuery($mobile){
                padding: 0;
                .sd_flex_1_auto{
                    width: 100%;
                    padding-left: 20px;
                }
            }
        }
        .sd_flex_1_auto{
            @include mediaQuery($extraLarge){
            // padding: 0 100px;
            width: 100%;
            }
            @include mediaQuery($large){
                padding: 0;
            }
        }
    }
    .sd_footer_logo{
        max-width: 520px;
        width: 100%;
        padding-right: 10px;
        padding: 32px 10px 18px 0;
        @include flex_dir_column;


        @include mediaQuery($desktop){
            padding: 0 0 26px; 
        }
        // @include mediaQuery($large){
        //     padding-left: 20px;
        // }
        @include mediaQuery($ipad){
            max-width: 295px;
        }
        @include mediaQuery($mobileLarge){
            max-width: 100%;
        }
        @include mediaQuery($mobile){
            // max-width: 110px;
        }


        .sd_footer_logos{
            // max-width: 120px;
            @include mediaQuery($ipad){
                @include justify_center;
                // max-width: 110px;
            }
        }
        @include mediaQuery($mobile){
            padding: 0;
        }


    .sd_footer_festival_logo{
        margin-right: 26px;
        @include mediaQuery($mobile){
            height: 66px;
            img{
                height: 100%;
            }
        }
    }
    .sd_footer_main_logo{ 
        width: 100%;
        display: flex;
        @include mediaQuery($mobileLarge){
            justify-content: space-between;
        }
        .footer_webby_logo{
            width: 104px;
            margin-left: 108px;
            @include mediaQuery($ipad){
                margin-left: 45px;
            }
        }
        a{
            max-width: 120px;
            width: 100%;
            height: 90px;
            img{
                max-height: 100%;
            }
        }
    }
    p{
        @include defaultFontStyleScreening($font_family_light, 13, 15, $white);
        text-transform: uppercase; 
        //font-family: 'La Nord V2.1 Semi';
        padding-top: 34px;
        @include mediaQuery($extraLarge){
            display: none;
        }
    }
    }

    .sd_footer_navbar{  
        // @include flex_shrink;    


        ul{ 
            list-style: none;   
            margin-right: 33px;     
            @include mediaQuery($desktop){  
                margin-right: 20px; 
            }   
            @include mediaQuery($extraLarge){   
                align-items: flex-end;
                justify-content: flex-end; 
                @include flex_wrap; 
                margin: 0 0 20px;    
            }   
            @include mediaQuery($large){
                margin-right: 0;
            }
            @include mediaQuery($large){    
                @include f_wrap;
                li{
                    width: 100%;
                }
            }   
            @include mediaQuery($mobile){   
                display: block; 
                margin: 20px 0;
            }   
        }   
        li{ 
            text-align: right;  
            padding: 9px 0 8px; 
            @include mediaQuery($extraLarge){   
                padding: 8px 20px;  
            }   
            @include mediaQuery($mobile){   
                text-align: right;  
                padding: 4px 20px;
            }   
            a{  
                @include defaultFontStyleScreening($font_family_medium, 18, 20, $white);  
                font-weight: 400;
                text-transform: uppercase;
                &:hover{    
                    color: $yellow; 
                }   
                @include mediaQuery($desktop){  
                    font-size: 18px;    
                }   
                @include mediaQuery($mobile){   
                    font-size: 15px;    
                }   
            }   
        }   
    }

    .sd_footer_navigation_bar{
        // position: absolute;
        bottom: 0;
        right: 0;
        @include flex_align_center;
        @include mediaQuery($extraLarge){
            position: static;
            @include flex_dir_column;
            width: 100%;
            // padding-right: 17px;
        }
        @include mediaQuery($mobile){
            padding-right: 0;
        }
    }
    .sd_footer_nav{
        // max-width: 414px;
        width: 414px;
        @include flex_shrink;


        @include mediaQuery($extraLarge){
            border-top: 2px solid $white;
            border-bottom: 2px solid $white;
        }
        @include mediaQuery($mobile){
            max-width: 100%;
            width: 100%;
        }
        ul{
            border-left: 2px solid $white;
            border-right: 2px solid $white;


            @include mediaQuery($ipad){
                max-width: 414px;
                margin: 0 auto;
            }
            @include mediaQuery($mobile){
                border: 0;
                max-width: 100%;
                width: 100%;
            }
            @include mediaQuery(374px){
                // @include flex_dir_column;
            }
            &:nth-child(2) {
                li {
                    border-bottom: 0;   
                    // max-width: 100% !important;
                }
            }


            li{
                border-right: 2px solid $white;
                border-bottom: 2px solid $white;
                width: 100%;
                list-style: none;
                max-width: 176px;
                @include mediaQuery($mobile){
                    max-width: 160px;
                }
                @include mediaQuery(374px){
                    // border: 0;
                }


                ul{
                    border: none;
                    padding: 0 23px 0 33px;
                    @include mediaQuery($mobile){ 
                        @include justify_center;
                        padding: 0;
                    }
                    @include mediaQuery(400px){ 
                        // @include justify_between;
                        padding: 0 20px ;
                    }
                    @include mediaQuery($mobileSmall){
                        // justify-content: flex-start;
                    }
                    @include mediaQuery(374px){
                        @include justify_center;
                        @include flex_dir_row;
                    }
                    > li{
                        border: none; 
                        width: auto;
                        margin-right: 29.17px;
                        @include flex_shrink;
                        &:last-child{
                            margin-right: 0;
                            // @include mediaQuery(400px){ 
                            //     margin-right: 22px;
                            // }
                        }
                        @include mediaQuery($mobile){
                            margin-right: 10px;
                        }
                    }
                    a{
                        padding: 0;
                    }
                }
                &:last-child{
                    border-right: 0;
                    max-width: 233px;
                    @include mediaQuery($mobile){
                        max-width: 310px;
                    }
                }
            }            
        }
        a{
            @include defaultFontStyleScreening($font_family_medium, 13, 15, $white);
            font-weight: 400;
            padding: 10px;
            text-align: center;
            height: 101px;    
            @include flex_center_center;
            @include transitionStyle(0.2, linear);
            &:hover{
                color: $yellow;
            }
            @include mediaQuery($mobile){
                height: 99px;   
            }
            @include mediaQuery(374px){
                @include flex_dir_column;
                height: 88px;
            }
        }
    }

    .sd_footer_copyright{
        @include mediaQuery($extraLarge){
            display: block;
            padding: 25px 18px 0;
            margin-bottom: 3px;            
        }
        @include mediaQuery(479px){
            padding: 25px 18px 20px;           
        }
        p{
            @include defaultFontStyleScreening($font_family_light, 12, 12, $white);
            @include mediaQuery($smallMobile) {
                text-align: center;
            }
        }
    }
}
