
// Tabs
.sd_films_tab_sec{ 
    .sd_films_tab_wpr{
        ul{
            @include flex;
            list-style: none;
            @include mediaQuery($mobile) {
                margin: 0 -2px;
            }
        }
        li{
            position: relative;
            width: 100%;
            max-width: 280px;
            border: 2px solid $black;
            // border: 2px solid $gainsboro;
            border-bottom: 0;
            margin: 0 -1px;
            &.active{
                border-color: $black;  
                &:after{
                    @include content;
                    right: -2px;
                    top: -2px;
                    width: 2px;
                    height: calc(100% + 2px);
                    background: $black;
                    z-index: 5;
                }       
                &:before{
                    @include content;
                    left: 0;
                    bottom: -3px;
                    width: 100%;
                    height: 4px;
                    background: $white;
                    z-index: 5;
                }   
                .sd_film_tab_button{
                    background: $white;
                    color: $black;
                }
            }

        }
        .sd_film_tab_button{
            position: relative;
            @include buttonReset;
            @include defaultFontStyleScreening($font_family_bold,22, 27, #EDEDED, 0.2px); 
            font-weight: 700;
            @include flex_center_center;
            background: #A4A4A4;
            text-transform: uppercase;
            padding: 6px 10px;
            width: 100%;
            height: 62px;
            @include mediaQuery($desktop) {
                @include mediaFontStyle(20, 30);
            }
            @include mediaQuery($ipad) {
                @include mediaFontStyle(18, 17.6);
                letter-spacing: -0.43px;
            } 
            @include mediaQuery($mobile) {
                @include flex_dir_column;
                padding: 12px 10px;
                @include mediaFontStyle(16, 16);
                font-size: 14px;
                .sd_question_button{                    
                    width: 14px;
                }
            } 
        }
    }
}
//chat Tabs
.sd_films_tab_sec_chat
{
    .sd_films_tab_wpr_chat
    {
        ul{
            @include flex;
            list-style: none;
            border-bottom: 2px solid #000000;
            @include mediaQuery($mobile) {
                margin: 0 -2px;
            }
        }
        li{
            position: relative;
            width: 100%;
            max-width: 135px;
            border: 2px solid #A4A4A4;
            // border: 2px solid $gainsboro;
            border-bottom: 0;
            margin: 0 -1px;
            &.active{
                border-color: $black;  
                &:after{
                    @include content;
                    right: -2px;
                    top: -2px;
                    width: 2px;
                    height: calc(100% + 2px);
                    background: $black;
                    z-index: 5;
                }       
                &:before{
                    @include content;
                    left: 0;
                    bottom: -3px;
                    width: 100%;
                    height: 4px;
                    background: $white;
                    z-index: 5;
                }   
                .sd_film_tab_button{
                    background: $white;
                    color: $black;
                }
            }

        }
        .sd_film_tab_button{
            position: relative;
            @include buttonReset;
            @include defaultFontStyleScreening($font_family_bold,22, 27, rgba(0, 0, 0, 0.3), 0.2px); 
            @include flex_center_center;
            // background: #A4A4A4;
            text-transform: uppercase;
            padding: 6px 10px;
            width: 100%;
            height: 39px;
            @include mediaQuery($desktop) {
                @include mediaFontStyle(20, 30);
            }
            @include mediaQuery($ipad) {
                @include mediaFontStyle(18, 17.6);
                letter-spacing: -0.43px;
            } 
            @include mediaQuery($mobile) {
                @include flex_dir_column;
                padding: 12px 10px;
                @include mediaFontStyle(14, 16);
                .sd_question_button{                    
                    width: 14px;
                }
            } 
        }
    }
}

.sd_films_tab_content{
    // border-top: 2px solid $black;
    background: $lightpink;
    padding-bottom: 70px;

    .sd_inner_container {
        @media (min-width: 1600px) and (max-width: 1680px){
            &{
                max-width: 100%;
                padding: 0 100px;
            }
        }
        @include mediaQuery(480px){
            padding: 0 15px;
        }
    }
    @include mediaQuery($ipad){
        background-size: contain;
        padding-bottom: 30px;
    }

    // .sd_films_tab_content_wpr{
    //     padding-bottom: 10px;
    // }
}

// Program Details
.sd_films_prgoram_details{
    padding: 70px 0 0;
    @include flex_justify_between;
    align-items: flex-start;

    @include mediaQuery(1600px) {
       justify-content: center;
    }
    @include mediaQuery(1024px) {
        flex-direction: column;
    }
    @include mediaQuery(768px) {
        padding: 50px 0 20px;
    }

    .sd_prgoram_details_desc{
        max-width: 620px;
        width: 100%;
        margin-right: 40px;
        @include mediaQuery(1024px) {
            max-width: 100%;
            margin: 0;
            padding: 0 0 50px;
        }
    }

    .sd_prgoram_details_desc_block{
        span{
            @include defaultFontStyleScreening($font_family_medium,18, 24, $black, -0.432px); 
            font-weight: 400;
            display: block;
        }
        h2{
            margin-bottom: 20px;
            @include defaultFontStyle(700, 40, 40, $black, 0.2px);
            text-transform: capitalize;
            // @include mediaQuery(600px) {
            //     @include mediaFontStyle(32, 40);
            //  }
        }
    }
    .sd_rtf_content{
        &>p{
            line-height: 0;
        }
        p{
            padding-bottom: 20px;
            @include mediaQuery(480px) {
               padding-bottom: 15px;
               font-size: 14px !important;
            }
        }
    }
    .sd_main_img
    {
        max-width: 680px;
        width: 100%;
        @include mediaQuery(1500px) {
            max-width: 500px;
        }
        @include mediaQuery(1024px) {
            max-width: 680px;
            margin: 0 auto;
        }
        .sd_film_img_title
        {
            span{
                @include defaultFontStyleScreening($font_family_medium,18, 24, $black, -0.432px); 
                font-weight: 400;
                display: block;
                // margin-bottom: 10px;
            } 
        }
    }
    .sd_prgoram_details_slider{
        margin-top: 18px;
    
        @include mediaQuery($desktop) {
            max-width: 545px;
        }
        @include mediaQuery(1024px) {
            max-width: 100%;
        }
        .slick-list{
            border-radius: 30px !important;
        }
        .sd_prgoram_details_block{
            position: relative;
            height: 0;
            padding-bottom: 56.25%;
            iframe, img, video {
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                border: 0;
                border-radius: 30px;
            }

        } 
    }
}

// About film
.sd_films_about_film{
    @include flex;
    padding-top: 45px;

    @include mediaQuery($ipad){
        @include flex_dir_column;
    }

    .sd_film_artists_credits_sec{        
        border-right: 2px solid $black;
        padding-bottom: 35px;
        @include mediaQuery($ipad) { 
            border-right: 0;
        }

        &:first-child{
            max-width: 405px;
            width: 100%;    
            padding-right: 30px;
            @include flex_shrink;
            @include mediaQuery($desktop){
                max-width: 100%;
                flex-shrink: 1;
            }   
            @include mediaQuery($ipad){
                padding-right: 0;
            }   
            .sd_film_artists_cr_pos{
                max-width: 112px;
                @include mediaQuery($ipad) {
                    max-width: 180px;
                }
            }    
        }
        &:last-child{
            border-right: 0;
            padding-left: 45px;
            width: 100%;
            @include mediaQuery($ipad){
                padding-left: 0;
            }   
            ul{
                column-count: 2;
                @include mediaQuery($desktop){
                    column-count: 1;
                }   
            }
        }
        .sd_film_artists_cr_name{
            // @include flex_shrink;
            @include mediaQuery($desktop){
                flex-shrink: 1;
            }   
        }
    }
    .sd_film_artists_header{
        padding-bottom: 35px;
    }
}

// More Info
.sd_films_more_info{
    padding-top: 50px;
    .sd_films_more_info_header{
        max-width: 560px;
        width: 100%;   
        margin: 15px 0 25px;
    }
    .sd_films_more_info_desc{
        column-count: 2;
        column-gap: 70px;
        @include mediaQuery($large){
            column-count: 1;
        }
        p{
            margin-bottom: 10px;
        }
    }
}
body .sd_rtf_content p:empty{
    display: none;
}