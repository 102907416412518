// Header Menu
.sd_navigation {
    list-style: none;
    @include transitionStyle(0.3, linear);
    @include mediaQuery($desktop){
        @include flex_dir_column;
        overflow: auto;
        position: absolute;
        width: 100%;
        padding: 0 0 60px;
        height: calc(100vh - 80px);
        background: $white;
    }

    li {
        // border-left: 2px solid $black;
        position: relative;
        @include flex_center_center;
        max-width: 160px;
        height: 100%;
        text-align: center;
        
        // @include mediaQuery(1500px){
        //     max-width: 110px;                
        // }

        @include mediaQuery($desktop){
            border-right: 0;
            // @include border_bottom_black;
            height: auto;
            min-width: 100%;
            padding: 8px 30px;
            border-left: 0;
        } 
        @include mediaQuery($desktop){
            li{
                &:hover{
                    a{
                        color: $white;
                    }
                }
            }
        }

        &:hover{
            @include minmediaQuery(1367px){
                ul{
                    display: block;
                }
            }            
        }
        // &.active{
        //     li{
        //         background-color: $lygreen !important; 
        //     }
        // }
        &.has-innerlist{

            @include mediaQuery($desktop){
                @include flex_dir_column;             
            }

            &.active{     
                border: none !important;
                // background-color: $lygreen !important;
               
                .sd_inner_navigation{
                    display: block;
                    // background-color: $lygreen !important;
                    
                } 
                      
            }
        }
        &.sd_sign_in_btn{
            max-width: 210px;
            margin-left: 22px;
            a{
                @include defaultFontStyleScreening($font_family_medium, 14, 24, $white, -0.336px);
                font-weight: 400;
                background: #000000;
                border-radius: 10px;
                padding: 7px 20px 8px;
            }
            @include mediaQuery($desktop){
                display: none
            }
        }
    }
    a{
        @include defaultFontStyleScreening($font_family_medium, 14, 24, $black, -0.336px);
        font-weight: 500;
        text-transform: uppercase;
        padding: 16px 22px 14px;
        @include mediaQuery(1650px){	
            padding: 16px 20px 14px;	
        }
        @include mediaQuery(1500px){
            padding: 16px 15px 14px;
            @include mediaFontStyle(14, 16);                     
        }
        @include mediaQuery($desktop){
            @include mediaFontStyle(18, 18);
            width: 100%;    
            &:hover{
                color: $black;
            }
        }
    }
    .sd_userProfile{
        display: none;
        @include mediaQuery($desktop){
            display: block;
            min-width: 100%;
            border-bottom: 2px solid $black;
            // padding: 16px 17px 20px 18px;
            padding: 5px 0;
       
            }
            .sd_user_profile_user  {
                padding-right: 15px;
                p{
                    font-size: 11px !important;
                    font-family: $font_family_medium !important;
                }
            }
            .sd_user_profile_image{
                width: 50px;
                height: 50px;
                border: 2px solid #e9e9e9;
                border-radius: 50%;
                img {
                    width: 100%;
                    height: 100%;
                    border-radius: 50%;
                }
                p{
                    margin-top: 5px;
                }
            }
            .sd_user_profile_cart {
                justify-content: center;
            }
            a{
                // padding: 0;
            }
            .sd_cart_btn{
                padding-right: 20px;
                button{
                    @include buttonReset;
                    position: relative;
                    width: 29px;
                    height: 28px;
                } 
                .sd_label{
                    position: absolute;
                    border-radius: 50%;
                    @include square(20px);
                    @include defaultFontStyleScreening($font-family-normal, 10, 10, $black, 0.32px);
                    @include flex_center_center;
                    top: -8px;
                    right: -14px;
        
                    &.sd_label_yellow{
                        background: $yellowish;
                    }
                }
            }
    }
    .toggle_arrow{
        position: absolute;
        top: 0;
        right: 20px;
        cursor: pointer;
        z-index: 5;
        width: 50px;
        height: 64px;
        display: none;
        @include mediaQuery($desktop){
            display: block;       
        }
        &:before{
            @include content;
            top: 50%;
            transform: translateY(-50%);
            right: 10px;
            @include transitionStyle(0.2, linear);
            @include square(0);
            border-left: 7px solid transparent;
            border-right: 7px solid transparent;            
            border-top: 7px solid $black;
        }                        
    }
}

// Inner Navigation
.sd_inner_navigation{
    position: absolute;
    top: 100%;
    left: 50%;
    // box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.2);
    z-index: 9;
    transform: translate(-50%, 0);
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.19);

    @include mediaQuery($desktop){
        position: static;     
        width: 100%;    
        border: 0;   
        transform: unset;
        left: 0;   
        box-shadow: none;         
    }
  
    li{
        width: 200px;
        max-width: 200px;
        background: $white;
        // @include border_bottom_black;
        border-left: 0; 
        text-align: right;
        @include transitionStyle(0.2, linear);
       @include mediaQuery($desktop){
           background: unset;
       }

       &:hover{
            a{
                background: $black;
                border-radius: 10px;
                color: $white;
                padding: 13px 14px 12px 15px;
            }
        }

        &:last-child{
            border-bottom: 0;
        }

        @include mediaQuery($desktop){
            width: 100%;
            max-width: 100%;     
            text-align: center;    
            padding: 0; 
            background: none;     
        }

        a{
            width: 100%;
            padding: 13px 14px 12px 15px;
            margin: 3px;
            -webkit-transition: .4s;
            -o-transition: .4s;
            transition: .4s;

            @include mediaQuery($desktop){
                @include mediaFontStyle(14, 16);  
                max-width: 200px;
            }
        }
    }
}

// Navigation menu
.sd_signin{
    @include mediaQuery($desktop){
    min-width: 100%;
    border-bottom: 5px solid $black;
    }
.sd_option{
  
    @include mediaQuery($desktop){
        // padding: 16px 17px 20px 18px;
        padding: 25px 0;
        justify-content: center;
    }
   
    @include align_center;
    @include justify_center;
    flex-flow: row-reverse;

.sd_menu_options{
   // width: 100%;
   width: 40%;
   margin-right: 20px;

    
    @include mediaQuery($desktop){
        display: block;
    }
    @include mediaQuery($mobile){
       
        // padding: 40px 17px 10px 18px;
    }
   
    .sd_menu_signin{
        a{
            @include defaultFontStyleScreening($font_family_medium, 16, 16, $black);
            letter-spacing: 1.1px;
            text-transform: uppercase;
            text-align: center;
            display: block;
            // margin-bottom: 56px; 
            @include mediaQuery($desktop){
                border-radius: 10px;
                background-color: #000000;
                color: #ffffff;
                padding: 10px 14px;
                font-size: 1.4rem;
                line-height: 2rem;
            }           
        }
    }
    .sd_menu_search{
        // position: relative;
        // width: 100%;
        @include mediaQuery($desktop){
            margin-left: 30px;
        }
        input{
            @include defaultFontStyle(700, 14, 14, $black);
            letter-spacing: 0.96px;
            border: none;
            background: $lightRed;
            width: 100%;
            padding: 16px 50px 12px;
            text-align: center;
            &::placeholder{
                color: $black;
            }
        }
        button{
            @include buttonReset;
            position: absolute;
            width: 24px;
            height: 25px;
            top: 10px;
            right: 16px;
        }
    }
}
}
}

// Search Menu
.serach_modal {
    // position: relative;
    position: fixed;
    width: 100%;
    height: auto;
    left: 0;
    top: 0px;
    // background: rgba(0, 0, 0, 0.5);
    z-index: 10;
    .sd_main_serach
    {
        display: flex;
        position: absolute;
        justify-content: space-between;
        max-width: 500px;
        width: 100%;
        top: 17px;
        right: 9px;
        align-items: center;
        z-index: 7;
        @include mediaQuery($mobileLarge){
            min-width: 100px !important;
            width: 96%;
            padding: 0 15px ;
        }
        .sd_serach_box
        {
            min-width: 440px;
            @include mediaQuery($mobileLarge){
                min-width: 330px;
            }
            @include mediaQuery($mobile){
                min-width: 280px;
            }
            form{
                position: relative;
                
                input
                {
                    @include serachbox;
                    background: $white;
                    &::placeholder{
                        color: $black;
                    }
                }
                .sd_error_msg
                {
                    position: absolute;
                    top: 20px;
                    right: 40px;
                    @include defaultFontStyle(400, 12, 12, $redpink);
                    @include mediaQuery($mobileLarge){
                        top: 65px;
                        right: 54px;
                    }
                   

                }
                .sd_enter_serach
                {
                    background: none;
                    border: none;
                    position: absolute;
                    top: 17px;
                    right: 8px;
                    cursor: pointer;
                    img{
                        margin-left: 12px;
                        width: 18px;
                        // cursor: pointer;
                    }
                }
            }
        }
        .sd_close_popup{
            @include square(28px);
            @include buttonReset;
            // @include flex_justify_center;
            display: flex;
            
            span{
                display: block;
                width: 2px;
                background: $black;
                height: 37px;
                &:first-child{
                    transform: rotate(-45deg) translate(4px, -3px);
                }
                &:last-child{
                    transform: rotate(45deg) translate(-4px, -3px);
                }
            }
        }
    }
}
.overlay_header{
    position: fixed;
    width:100%;
    height: 78px;
    right: 0;
    top: 0px;
    // background: rgba(0,0,0,0.4);
    background: $white;    
    // background: rgba(256,256,256,1);
    z-index: 6;
}